import React, { Component } from "react";
import logo from "../../public/ME_logo_white.png";
import { Col, Row } from "react-bootstrap";
import "./Header.scss";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // activeNav: "analyse"
    };
  }

  render() {
    return (
      <div className="header">
        <div className="head-container">
          <Row className="logoContainer">
            <Col className="mobile-center">
              <img className="logo" src={logo} alt="logo" />
            </Col>
          </Row>
          <Row className="mainContent">
            <Col className="description">
              <p>
                Everyone has a gut microbiome (the trillions of bacteria living
                in your gut), which we like to call “Gutty”. Your Gutty needs to
                be nourished daily, just like you.
                <br />
                Unfortunately, our modern lifestyles are impacting our gut and
                eroding the foundation of our wellness. Our diets have decreased
                in foods known to feed our gut microbiome such as fruits,
                vegetables and other plant-based or fermented foods. We may be
                starving our Gutty. As the gut microbiome is a cornerstone of
                our health & wellbeing, we need to take care of Gutty, in return
                Gutty will take care of us.
                <br />
                This research program, known as THDMI, “The Human Diets &
                Microbiome Initiative”, aims to discover the best diets and
                foods on the planet that can nourish the Gutties of the world
                using the latest technology, such as shotgun metagenomic
                sequencing.
                <br />
                Thank to citizen scientists’ contributions, this tool will allow
                you to explore the world of microbiome and to visualise the
                microbiome profiles thank to most representative bacteria. It’s
                an amazing source of knowledge and a nice data playground coming
                from 20 000 citizen scientist samples!
                <br />
                We will add extra features such as microbiome diversity, gender,
                diet… so stay connected and give us feedback.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="mobile-center">
              <span className="howItWorks" onClick={this.props.openModal}>
                How it works
              </span>
            </Col>
          </Row>
        </div>
        {/* <Row>
          <Col>

          </Col>
        </Row> */}

        <div className="scrollContainer">
          <span onClick={this.props.scroll}>
            <i className="material-icons">expand_more</i>
          </span>
        </div>
      </div>
    );
  }
}

export default Header;
