import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import modalImg1 from "../../public/modals-01.png";
import modalImg2 from "../../public/modals-02.png";
import modalImg3 from "../../public/modals-03.png";
import modalImg4 from "../../public/modals-04.png";
import modalImg5 from "../../public/modals-05.png";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";
import "./ModalHeader.scss";

/**
 * @classdesc TreeBloc component is bloc of volontaire informations
 */
class ModalHeader extends Component {
  constructor(props) {
    super(props);
    this.resize = React.createRef();
    this.state = {
      show: false,
      index: 0,
      direction: null,
      numberPages: 5,
      nextArrow: (
        <button className="previous" onClick={this.previous}>
          Previous
        </button>
      ),
      prevArrow: (
        <button className="previous" onClick={this.previous}>
          Previous
        </button>
      ),
    };
  }

  handleModal = () => {
    this.setState({ show: !this.state.show });
  };

  goToPage = (page) => {
    this.setState({ show: true }, () => {
      this.slider.slickGoTo(page);
    });
  };

  triggerModal = () => {
    this.setState({ show: !this.state.show });
  };

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      this.state.show
    ) {
      this.setState({ show: false });
    }
  };

  next = () => {
    this.slider.slickNext();
  };
  previous = () => {
    this.slider.slickPrev();
  };

  getWindowDimension = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    return { width, height };
  };

  /**
   * render function of Modal component
   * @return {JSX}
   */
  render() {
    const ArrowLeft = (props) => <button {...props}>Previous</button>;
    const ArrowRight = (props) => <button {...props}>Next</button>;
    const { width, height } = this.getWindowDimension();

    const settings = {
      dots: true,
      infinite: true,
      fade: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      //   variableWidth: true,
      centerMode: true,
      lazyLoad: true,
      arrows: true,
      prevArrow: <ArrowLeft />,
      nextArrow: <ArrowRight />,
    };

    // mobile display
    if (width < 767) {
      return (
        <Modal
          className="mobile-modal"
          show={this.state.show}
          onHide={this.handleModal}
        >
          <div className="mobile-content">
            <span className="modal-close" onClick={this.handleModal}>
              &#10005;
            </span>
            <Slider {...settings} ref={(c) => (this.slider = c)}>
              <div className="Slider">
                <div className="modal-title">
                  <span className="titleModal">
                    Choose country of birth or residence
                  </span>
                </div>
                <div className="modal-description">
                  <p></p>
                </div>
                <div className="modal-image">
                  <img
                    src={modalImg1}
                    className="carousel-images"
                    alt="country residence"
                  />
                </div>
              </div>

              <div className="Slider">
                <div className="modal-title">
                  <span className="titleModal">Define the age range</span>
                </div>
                <div className="modal-description">
                  <p></p>
                </div>
                <div className="modal-image">
                  <img
                    src={modalImg2}
                    className="carousel-images"
                    alt="country residence"
                  />
                </div>
              </div>
              <div className="Slider">
                <div className="modal-title">
                  <span className="titleModal">
                    Citizen scientist World Map
                  </span>
                </div>
                <div className="modal-description">
                  <p>
                    This tool allows you and citizen scientist to play with
                    microbiome data and compare the variations between different
                    populations or groups of people. In this panel, you can
                    select population based on country of residence or birth and
                    filter by age range. Your selection is reflected in others
                    microbiome-based charts.
                  </p>
                </div>
                <div className="modal-image">
                  <img
                    src={modalImg3}
                    className="carousel-images"
                    alt="country residence"
                  />
                </div>
              </div>
              <div className="Slider">
                <div className="modal-title">
                  <span className="titleModal">Gut Microbiome Diversity</span>
                </div>
                <div className="modal-description">
                  <p>
                    This tool allows you to visualize the distribution of the
                    alpha diversity, that is the diversity of bacteria that
                    inhabit your gut and their abundance. Here, the Shannon
                    index is depicted. It takes into account both richness (how
                    many bacteria?) and evenness (how abundant is each bacterium
                    relatively to each other?). A high value reflects the
                    presence of many species with a homogenous repartition.
                  </p>
                </div>
                <div className="modal-image">
                  <img
                    src={modalImg3}
                    className="carousel-images"
                    alt="country residence"
                  />
                </div>
              </div>
              <div className="Slider">
                <div className="modal-title">
                  <span className="titleModal">Microbiome profiles</span>
                </div>
                <div className="modal-description">
                  <p>
                    This tool allows you to visualize the abundance of 10
                    bacterial genera (families of bacteria), the most dominant
                    in the human gut microbiome. Each percentage represents a
                    bacterial genera quantity in association with your world map
                    selected filters. When you click on one of the bacterial
                    genera, its abundance is painted as a color gradient to
                    microbiome map and represent variation among citizen
                    scientist samples. For more information about bacteria
                    genera you can click to the hyperlink associated with.
                  </p>
                </div>
                <div className="modal-image">
                  <img
                    src={modalImg4}
                    className="carousel-images"
                    alt="country residence"
                  />
                </div>
              </div>
              <div className="Slider">
                <div className="modal-title">
                  <span className="titleModal">Microbiome Map</span>
                </div>
                <div className="modal-description">
                  <p>
                    Given the geographic distance between us, we can compute
                    microbiome distance and represent samples profile within a
                    microbiome map. Each point represents a single sample, and
                    the distance between points represents how the microbial
                    profile are different from one sample to another. If you
                    have selected a bacterial genus, color gradient account for
                    its abundance variation among citizen scientist samples.
                  </p>
                </div>
                <div className="modal-image">
                  <img
                    src={modalImg5}
                    className="carousel-images"
                    alt="country residence"
                  />
                </div>
              </div>
            </Slider>
          </div>
        </Modal>
      );
    } else {
      return (
        <Modal
          className="modalHeader computer-modal"
          show={this.state.show}
          onHide={this.handleModal}
        >
          <div className="computer-content">
            <div className="ModalContent">
              <Slider {...settings} ref={(c) => (this.slider = c)}>
                <div className="Slider">
                  <Row>
                    <Col md={12}>
                      <img
                        src={modalImg1}
                        alt="country"
                        className="computer-modale-image"
                      />
                    </Col>
                    <div className="titlecolumn">
                      <span className="titleModal">
                        Choose country of birth or residence
                      </span>
                    </div>
                    <Col md={12}>
                      <p></p>
                    </Col>
                  </Row>
                </div>
                <div className="Slider">
                  <Row>
                    <Col md={12}>
                      <img
                        src={modalImg2}
                        alt="age range"
                        class="computer-modale-image"
                      />
                    </Col>
                    <div className="titlecolumn">
                      <span className="titleModal">Define the age range</span>
                    </div>
                    <Col md={12}>
                      <p></p>
                    </Col>
                  </Row>
                </div>
                <div className="Slider">
                  <Row>
                    <Col md={12}>
                      <img
                        src={modalImg3}
                        alt="citizen scientist world map"
                        class="computer-modale-image"
                      />
                    </Col>
                    <div className="titlecolumn">
                      <span className="titleModal">
                        Citizen scientist World Map
                      </span>
                    </div>
                    <Col md={12}>
                      <p className="modaltext">
                        This tool allows you and citizen scientist to play with
                        microbiome data and compare the variations between
                        different populations or groups of people. In this
                        panel, you can select population based on country of
                        residence or birth and filter by age range. Your
                        selection is reflected in others microbiome-based
                        charts.
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="Slider">
                  <Row>
                    <Col md={12}>
                      <img
                        src={modalImg3}
                        alt="Alpha Diversity Profiles"
                        class="computer-modale-image"
                      />
                    </Col>
                    <div className="titlecolumn">
                      <span className="titleModal">
                        Gut Microbiome Diversity
                      </span>
                    </div>
                    <Col md={12}>
                      <p className="modaltext">
                        This tool allows you to visualize the distribution of
                        the alpha diversity, that is the diversity of bacteria
                        that inhabit your gut and their abundance. Here, the
                        Shannon index is depicted. It takes into account both
                        richness (how many bacteria?) and evenness (how abundant
                        is each bacterium relatively to each other?). A high
                        value reflects the presence of many species with a
                        homogenous repartition.
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="Slider">
                  <Row>
                    <Col md={12}>
                      <img
                        src={modalImg4}
                        alt="microbiome profiles"
                        class="computer-modale-image"
                      />
                    </Col>
                    <div className="titlecolumn">
                      <span className="titleModal">Microbiome profiles</span>
                    </div>
                    <Col md={12}>
                      <p className="modaltext">
                        This tool allows you to visualize the abundance of the
                        10 most dominant bacterial genera (families of bacteria)
                        of the human gut microbiome. Each curve represents the
                        distribution of the associated bacterial genus according
                        to filters. Each percentage represents the quantity of a
                        bacterial genera in association with the selected
                        filters on the world map. When you click on one of the
                        bacterial genera, its abundance is painted as a color
                        gradient to the microbiome map, and it represent
                        variation among citizen scientist samples. For more
                        information about bacteria genera you can click on the
                        associated hyperlink.
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="Slider">
                  <Row>
                    <Col md={12}>
                      <img
                        src={modalImg5}
                        alt="microbiome map"
                        class="computer-modale-image"
                      />
                    </Col>
                    <div className="titlecolumn">
                      <span className="titleModal">Microbiome Map</span>
                    </div>
                    <Col md={12}>
                      <p className="modaltext">
                        Given the geographic distance between us, we can compute
                        microbiome distance and represent samples profile within
                        a microbiome map. Each point represents a single sample,
                        and the distance between points represents how the
                        microbial profile are different from one sample to
                        another. If you have selected a bacterial genus, color
                        gradient account for its abundance variation among
                        citizen scientist samples.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Slider>
            </div>
          </div>
        </Modal>
      );
    }
  }
}
export default ModalHeader;
