import React from "react";
import * as d3 from "d3";
import { connect } from "react-redux";
import gemGif from "../../public/gif_microbiome.gif";
import "./AlphaDiversity.scss";

const mapStateToProps = (state, action) => {
  return {
    countries: state.DispatchCountriesListReducer,
    countryType: state.DispatchFiltreMapReducer.countryType,
    barSelected: state.DispatchFiltreBarChartReducer.barSelected,
    age: state.DispatchFiltreAgeReducer.age,
  };
};

let isLoading = false;

class AlphaDiversity extends React.Component {
  constructor(props) {
    super(props);
    this.countrySelected = [];
    this.state = {
      isLoading: false,
      data: [],
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.microbiomeData !== this.props.microbiomeData) {
      if (nextProps.microbiomeData) {
        this.setState({ data: nextProps.microbiomeData }, () => {
          this.computeAlphaDiversity();
        });
      }
      return true;
    }

    if (nextProps.isLoading !== isLoading) {
      isLoading = nextProps.isLoading;
      return true;
    }

    return false;
  }

  computeAlphaDiversity = () => {
    const dataToReturn = [];
    if (this.state.data && this.state.data.length > 0) {
      this.state.data.forEach((e) =>
        dataToReturn.push({
          shannon: parseFloat(e.alphaDiversity[0].shannon).toFixed(3),
        })
      );
      this.alphaDiversity(dataToReturn);
    }
  };

  alphaDiversity = (data) => {
    const margin = { top: 20, right: 20, bottom: 50, left: 60 },
      width = 880 - margin.left - margin.right,
      height = 650 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    let svg = d3
      .select("#alphaDiversity")
      .append("svg")
      .attr("viewBox", `0 0 900 650`)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // X axis: scale and draw:
    let x = d3
      .scaleLinear()
      .domain([0, Math.ceil(Math.max(...data.map((el) => el.shannon)))]) // can use this instead of 1000 to have the max of data: d3.max(data, function(d) { return +d.price })
      .range([0, width]);
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    // set the parameters for the histogram
    let histogram = d3
      .histogram()
      .value(function (d) {
        return d.shannon;
      }) // I need to give the vector of value
      .domain(x.domain()) // then the domain of the graphic
      .thresholds(x.ticks(70)); // then the numbers of bins

    // And apply this function to data to get the bins
    let bins = histogram(data);

    // Y axis: scale and draw:
    let y = d3.scaleLinear().range([height, 0]);
    y.domain([
      0,
      d3.max(bins, function (d) {
        return d.length;
      }),
    ]); // d3.hist has to be called before the Y axis obviously

    svg.append("g").call(d3.axisLeft(y));

    // Add X axis label:
    svg
      .append("text")
      .attr("text-anchor", "end")
      .attr("x", width / 2)
      .attr("y", height + 40)
      .attr("font-size", "14px")
      .attr("fill", "rgb(211, 211, 211)")
      .style("transform", "translate(55px)")
      .text("Gut microbiome alpha-diversity");

    // Add Y axis label:
    svg
      .append("text")
      .attr("width", 100)
      .attr("height", 15)
      .attr("y", 15 - margin.left)
      .attr("x", -height / 1.7)
      .attr("font-size", "14px")
      .attr("fill", "rgb(211, 211, 211)")
      .attr("transform", "rotate(-90)")
      .text("Number of samples");

    // append the bar rectangles to the svg element
    svg
      .selectAll("rect")
      .data(bins)
      .enter()
      .append("rect")
      .attr("x", 1)
      .attr("transform", function (d) {
        return "translate(" + x(d.x0) + "," + y(d.length) + ")";
      })
      .attr("width", function (d) {
        return x(d.x1) - x(d.x0) - 1;
      })
      .attr("height", function (d) {
        return height - y(d.length);
      })
      .style("fill", "#399FFA");
  };

  render() {
    return (
      <div className="graph-padding">
        <span className="graphLabel">
          <div className="title">
            Alpha Diversity
            <span onClick={() => this.props.getInfo(3)}>
              <i className="infoIcon material-icons">info</i>
            </span>
          </div>
        </span>
        <div className="graphContainer loading-block">
          {isLoading ? (
            <img src={gemGif} className="loading-gif" alt="Loading..." />
          ) : (
            <div id="alphaDiversity"></div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(AlphaDiversity);
