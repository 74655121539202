import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import activeBirthIcon from "../../public/birthWhite.png";
import birthIcon from "../../public/birthGreen.png";
import activeResidenceIcon from "../../public/residenceWhite.png";
import residenceIcon from "../../public/residenceGreen.png";
import omnivoreIcon from "../../public/filters/omnivore_g.png";
import activeOmnivoreIcon from "../../public/filters/omnivore_w.png";
import veganIcon from "../../public/filters/vegan_g.png";
import activeVeganIcon from "../../public/filters/vegan_w.png";
import veggieIcon from "../../public/filters/veggie_g.png";
import activeVeggieIcon from "../../public/filters/veggie_w.png";
import { Col, Row } from "react-bootstrap";
import InputRange from "react-input-range";
import { Multiselect } from "multiselect-react-dropdown";

import { dispatch_bars_filter } from "../Actions/DispatchFiltreBarChartActions";
import { dispatch_age_filter } from "../Actions/DispatchFiltreAgeActions";
import {
  ClearCountry,
  addCountry,
} from "../Actions/DispatchCountriesListActions";
import { connect } from "react-redux";
import qs from "query-string";
import { API_URL } from "../../shared/api";
import axios from "axios";
import {
  dispatch_map_filter,
  reset_map,
} from "../Actions/DispatchFiltreMapActions";

import "./ModalFilters.scss";

const initialState = {
  show: false,
  index: 0,
  direction: null,
  ageValue: { min: 0, max: 0 },
  ageRange: { min: 0, max: 0 },
  plantsValue: { min: 0, max: 50 },
  plantsRange: { min: 0, max: 50 },
  countriesSelected: [],
  activeFilter: null,
  activeDietFilter: null,
  dietType: null,
  diet: null,
};

const mapDispatchToProps = {
  dispatch_map_filter,
  dispatch_age_filter,
  dispatch_bars_filter,
  addCountry,
  reset_map,
  ClearCountry,
};

const mapStateToProps = (state, action) => {
  return {
    countries: state.DispatchCountriesListReducer,
    countryType: state.DispatchFiltreMapReducer.countryType,
    barSelected: state.DispatchFiltreBarChartReducer.barSelected,
    age: state.DispatchFiltreAgeReducer.age,
    message: state.DispatchFiltreMapReducer.message,
  };
};

class ModalFilters extends Component {
  constructor(props) {
    super(props);
    this.resize = React.createRef();
    this.multiselectRef = React.createRef();
    this.state = initialState;
  }

  componentDidMount() {
    this.onLoad();
  }

  onLoad() {
    this.getAvailableAges();
    if (this.props.countriesSelected) {
      this.setState({ countriesSelected: this.props.countriesSelected });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.countriesSelected &&
      nextProps.countriesSelected !== this.state.countriesSelected
    ) {
      this.setState({ countriesSelected: nextProps.countriesSelected });
      return false;
    }
    return true;
  }

  getAvailableAges = () => {
    axios
      .get(`${API_URL}/ages`)
      .then((res) => {
        this.setState(
          {
            ageValue: {
              min: Math.min(...res.data),
              max: Math.max(...res.data),
            },
            ageRange: {
              min: Math.min(...res.data),
              max: Math.max(...res.data),
            },
          },
          () => {
            this.setState({ initialState: this.state });
          }
        );
      })
      .catch((err) => console.error(err));
  };

  selectFilter = (e) => {
    this.setState({
      activeFilter: e.target.id,
    });
    this.props.dispatch_map_filter(e.target.id);
  };

  selectDietFilter = (e) => {
    this.setState({
      activeDietFilter: e.target.id,
    });
  };

  triggerModal = () => {
    this.setState({ show: !this.state.show });
  };

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      this.state.show
    ) {
      this.setState({ show: false });
    }
  };

  getWindowDimension = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    return { width, height };
  };

  clearFilter = () => {
    this.setState({ ...initialState, show: true });
    this.onLoad();
    this.multiselectRef.current.resetSelectedValues();
    this.props.ClearCountry([]);
    this.props.reset_map("");
    this.props.resetSelection();
    this.props.dispatch_map_filter();
  };

  sendFilters = () => {
    const {
      dietType,
      plantsValue: diet,
      ageValue: age,
      plantsRange,
      ageRange,
    } = this.state;
    const countries = this.props.countriesSelected;
    const countryType = this.props.countryType;
    const countryOfBirth = countryType === "birth" ? countries : null;
    const countryResidence = countryType === "residence" ? countries : null;
    this.props.triggerFilters(
      countryResidence,
      countryOfBirth,
      dietType ? [dietType] : null,
      JSON.stringify(diet) === JSON.stringify(plantsRange) ? null : diet,
      JSON.stringify(age) === JSON.stringify(ageRange) ? null : age
    );
    this.triggerModal();
  };

  /**
   * render function of Modal component
   * @return {JSX}
   */
  render() {
    const { max, min } = this.state.ageRange;

    return (
      <Modal
        className="mobile-modal"
        show={this.state.show}
        onHide={this.triggerModal}
      >
        <div className="mobile-content" id="overflow-y__auto">
          <div className="modal-header__title">
            Filters
            <span
              className="modal-close"
              id="close-button__color"
              onClick={this.triggerModal}
            >
              &#10005;
            </span>
          </div>

          <div className="modal-filter">
            <div className="modal-subtitle">BIRTH / RESIDENCE</div>
            <div className="modal-buttons">
              <span
                onClick={this.selectFilter}
                id="birth"
                className={`spanFilter roundedSpan ${
                  this.state.activeFilter === "birth" && "activeFilter"
                }`}
              >
                <img
                  className="iconFilter"
                  alt="birth icon filter"
                  src={
                    this.state.activeFilter === "birth"
                      ? activeBirthIcon
                      : birthIcon
                  }
                />
                Country of Birth
              </span>
              &nbsp;
              <span
                onClick={this.selectFilter}
                id="residence"
                className={`spanFilter roundedSpan ${
                  this.state.activeFilter === "residence" && "activeFilter"
                }`}
              >
                <img
                  className="iconFilter"
                  alt="residence icon filter"
                  src={
                    this.state.activeFilter === "residence"
                      ? activeResidenceIcon
                      : residenceIcon
                  }
                />
                Country of Residence
              </span>
            </div>
          </div>
          <hr />
          <div className="dropdown-select__padding">
            <Multiselect
              ref={this.multiselectRef}
              options={this.props.availableCountries}
              selectedValues={this.state.countriesSelected}
              onSelect={(country) =>
                this.props.addCountry(country[country.length - 1])
              }
              placeholder="Select countries"
              showCheckbox
              isObject={false}
              name="countries"
            />
            {this.state.countriesSelected.length > 0 &&
              !this.props.countryType && (
                <span className="error">
                  Please select a country type in order to filter by country.
                  See buttons above.
                </span>
              )}
          </div>
          <hr />
          <div className="modal-filter">
            <div className="modal-subtitle">AGE</div>
            <div className="modal-buttons">
              <Col>
                <InputRange
                  maxValue={max || 100}
                  minValue={min || 0}
                  value={this.state.ageValue}
                  onChange={(ageValue) => this.setState({ ageValue })}
                />
                <Row className="rangeAge">
                  <Col id="age-filters">
                    <Row>
                      <Col className="separator">
                        <span className="grey">Minimum age</span>
                      </Col>
                      <Col>
                        <span className="grey">Maximum age</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="value-input__text separator">
                        <span>{this.state.ageValue.min} </span>
                        <span>
                          {this.state.ageValue.min < 2 ? "year" : "years"}
                        </span>
                      </Col>
                      <Col className="value-input__text">
                        <span>{this.state.ageValue.max} </span>
                        <span>
                          {this.state.ageValue.max < 2 ? "year" : "years"}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
          <hr />
          <div className="modal-filter">
            <div className="modal-subtitle">TYPE OF DIET</div>
            <div className="modal-buttons">
              <Row>
                <Col>
                  <span
                    id="diet-type__vegan"
                    className={`spanFilter roundedSpan ${
                      this.state.activeDietFilter === "diet-type__vegan" &&
                      "activeFilter"
                    }`}
                    onClick={(value) => {
                      this.selectDietFilter(value);
                      this.setState({ dietType: "Vegan" });
                    }}
                  >
                    <img
                      className="iconFilter"
                      alt="diet vegan"
                      src={
                        this.state.activeDietFilter === "diet-type__vegan"
                          ? activeVeganIcon
                          : veganIcon
                      }
                    />
                    Vegan
                  </span>
                  &nbsp;
                  <span
                    onClick={this.selectDietFilter}
                    id="diet-type__omnivore"
                    className={`spanFilter roundedSpan ${
                      this.state.activeDietFilter === "diet-type__omnivore" &&
                      "activeFilter"
                    }`}
                    onClick={(value) => {
                      this.selectDietFilter(value);
                      this.setState({ dietType: "Omnivore" });
                    }}
                  >
                    <img
                      className="iconFilter"
                      id="diet-type__omnivore"
                      alt="diet omnivore"
                      src={
                        this.state.activeDietFilter === "diet-type__omnivore"
                          ? activeOmnivoreIcon
                          : omnivoreIcon
                      }
                    />
                    Omnivore
                  </span>
                  &nbsp;
                </Col>
                <Col>
                  <span
                    onClick={this.selectDietFilter}
                    id="diet-type__vegetarian"
                    className={`spanFilter roundedSpan ${
                      this.state.activeDietFilter === "diet-type__vegetarian" &&
                      "activeFilter"
                    }`}
                    onClick={(value) => {
                      this.selectDietFilter(value);
                      this.setState({ dietType: "Vegetarian" });
                    }}
                  >
                    <img
                      className="iconFilter"
                      alt="diet vegetarian"
                      src={
                        this.state.activeDietFilter === "diet-type__vegetarian"
                          ? activeVeggieIcon
                          : veggieIcon
                      }
                    />
                    Vegetarian
                  </span>
                </Col>
              </Row>
            </div>
          </div>
          <hr />
          <div className="modal-filter">
            <div className="modal-subtitle">NUMBER OF PLANTS PER WEEK</div>
            <div className="modal-buttons">
              <Col>
                <InputRange
                  maxValue={50}
                  minValue={0}
                  value={this.state.plantsValue}
                  onChange={(plantsValue) => this.setState({ plantsValue })}
                />
                <Row className="rangeAge">
                  <Col id="age-filters">
                    <Row>
                      <Col className="separator">
                        <span className="grey">Minimum plants</span>
                      </Col>
                      <Col>
                        <span className="grey">Maximum plants</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="value-input__text separator">
                        <span>{this.state.plantsValue.min} </span>
                        <span>
                          {this.state.plantsValue.min < 2 ? "plant" : "plants"}
                        </span>
                      </Col>
                      <Col className="value-input__text">
                        <span>{this.state.plantsValue.max} </span>
                        <span>
                          {this.state.plantsValue.max < 2 ? "plant" : "plants"}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </div>
        <div className="modal-filter-footer">
          <span onClick={this.clearFilter} className={`spanFilter roundedSpan`}>
            Clear all
          </span>
          <span
            onClick={this.sendFilters}
            id="save_filters"
            className={`spanFilter roundedSpan`}
          >
            Submit
          </span>
        </div>
      </Modal>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ModalFilters);
