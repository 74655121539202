import React, { Component } from "react";
import { connect } from "react-redux";
import {
  dispatch_map_filter,
  reset_map,
} from "../Actions/DispatchFiltreMapActions";
import { dispatch_age_filter } from "../Actions/DispatchFiltreAgeActions";
import { ClearCountry } from "../Actions/DispatchCountriesListActions";
import { dispatch_bars_filter } from "../Actions/DispatchFiltreBarChartActions";
import { Col, Row } from "react-bootstrap";
import InputRange from "react-input-range";
import ShareLink from "react-linkedin-share-link";
import activeBirthIcon from "../../public/birthWhite.png";
import omnivoreIcon from "../../public/filters/omnivore_g.png";
import activeOmnivoreIcon from "../../public/filters/omnivore_w.png";
import veganIcon from "../../public/filters/vegan_g.png";
import activeVeganIcon from "../../public/filters/vegan_w.png";
import veggieIcon from "../../public/filters/veggie_g.png";
import activeVeggieIcon from "../../public/filters/veggie_w.png";
import birthIcon from "../../public/birthGreen.png";
import howItWorksGreyIcon from "../../public/filters/how_it_works_g.svg";
import howItWorksOrangeIcon from "../../public/filters/how_it_works_o.svg";
import filterOrangeIcon from "../../public/filters/filter_o.svg";
import filterGreyIcon from "../../public/filters/filter_g.svg";
import shareGreyIcon from "../../public/filters/share_g.svg";
import activeResidenceIcon from "../../public/residenceWhite.png";
import residenceIcon from "../../public/residenceGreen.png";
import axios from "axios";
import qs from "query-string";
import "./Filters.scss";
import { API_URL } from "../../shared/api";

let initialState = {};

const mapDispatchToProps = {
  dispatch_map_filter,
  dispatch_age_filter,
  dispatch_bars_filter,
  reset_map,
  ClearCountry,
};

const mapStateToProps = (state, action) => {
  return {
    countries: state.DispatchCountriesListReducer,
    countryType: state.DispatchFiltreMapReducer.countryType,
    barSelected: state.DispatchFiltreBarChartReducer.barSelected,
    age: state.DispatchFiltreAgeReducer.age,
    message: state.DispatchFiltreMapReducer.message,
  };
};

class Filters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ageValue: { min: 0, max: 0 },
      ageRange: { min: 0, max: 0 },
      plantsValue: { min: 0, max: 50 },
      plantsRange: { min: 0, max: 50 },
      activeFilter: null,
      activeDietFilter: null,
      dietType: null,
      diet: null,
    };
  }

  componentDidMount() {
    this.getCountryParamsFromUrl();
    this.getAvailableAges();
    this.isMobile() && this.handleMobileFiltersDisplay();
  }

  getCountryParamsFromUrl = () => {
    if (this.props.location.search) {
      const parsedObject = qs.parse(this.props.location.search);
      this.setState({
        activeFilter: parsedObject.countryType,
      });
    }
  };

  getAvailableAges = () => {
    axios
      .get(`${API_URL}/ages`)
      .then((res) => {
        if (this.props.location.search) {
          const parsedObject = qs.parse(this.props.location.search);
          this.setState({
            ageValue: {
              min: parsedObject.minAge
                ? parseInt(parsedObject.minAge)
                : Math.min(...res.data),
              max: parsedObject.maxAge
                ? parseInt(parsedObject.maxAge)
                : Math.max(...res.data),
            },
            ageRange: {
              min: Math.min(...res.data),
              max: Math.max(...res.data),
            },
          });
          this.props.history.push({
            pathname: "/",
            search: "",
          });
        } else {
          this.setState(
            {
              ageValue: {
                min: Math.min(...res.data),
                max: Math.max(...res.data),
              },
              ageRange: {
                min: Math.min(...res.data),
                max: Math.max(...res.data),
              },
            },
            () => {
              this.setState({ initialState: this.state });
            }
          );
        }
      })
      .catch((err) => console.error(err));
  };

  selectFilter = (e) => {
    this.setState({
      activeFilter: e.target.id,
    });
    this.props.dispatch_map_filter(e.target.id);
  };

  selectDietFilter = (e) => {
    this.setState({
      activeDietFilter: e.target.id,
    });
  };

  clearFilter = (e) => {
    this.setState(this.state.initialState);
    this.props.ClearCountry([]);
    this.props.reset_map("");
    this.props.resetSelection();
    this.props.dispatch_map_filter();
  };

  handleMobileFiltersDisplay = () => {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset;
      if (document.getElementById("filter-bar")) {
        if (currentScrollPos > 55) {
          document.getElementById("filter-bar").style.display = "flex";
        } else {
          document.getElementById("filter-bar").style.display = "none";
        }
      }
      prevScrollpos = currentScrollPos;
    };
  };

  /**
   * This methods returns true if user is on mobile
   */
  isMobile = () => {
    return window.innerWidth <= 850 && window.innerHeight <= 850;
  };

  render() {
    const { min, max } = this.state.ageRange;
    return (
      <React.Fragment>
        <div className="fitlersBloc computer-bloc">
          <Row className="bloc-padding filters-grid">
            <Col lg={10}>
              <Row md={12}>
                <Col className="filter-bloc__text-placement" md={6}>
                  &nbsp;
                  <span className="grey">BIRTH / RESIDENCE</span>
                  <br />
                  <Row>
                    <Col lg={12}>
                      <span
                        onClick={this.selectFilter}
                        id="birth"
                        className={`spanFilter roundedSpan ${
                          this.state.activeFilter === "birth" && "activeFilter"
                        }`}
                      >
                        <img
                          className="iconFilter"
                          alt="birth icon filter"
                          src={
                            this.state.activeFilter === "birth"
                              ? activeBirthIcon
                              : birthIcon
                          }
                        />
                        Country of Birth
                      </span>
                      &nbsp;
                    </Col>
                    <Col lg={12}>
                      <span
                        onClick={this.selectFilter}
                        id="residence"
                        className={`spanFilter roundedSpan ${
                          this.state.activeFilter === "residence" &&
                          "activeFilter"
                        }`}
                      >
                        <img
                          className="iconFilter"
                          alt="residence icon filter"
                          src={
                            this.state.activeFilter === "residence"
                              ? activeResidenceIcon
                              : residenceIcon
                          }
                        />
                        Country of Residence
                      </span>
                      &nbsp;
                    </Col>
                  </Row>
                </Col>
                <Col className="filter-bloc__text-placement" md={6}>
                  &nbsp;
                  <span className="grey">AGE</span>
                  <br />
                  {this.state.ageRange.max &&
                    this.state.ageRange.min &&
                    this.state.ageValue && (
                      <Col>
                        <InputRange
                          maxValue={max ? max : this.state.ageRange.max || 0}
                          minValue={min ? min : this.state.ageRange.min || 0}
                          value={this.state.ageValue}
                          onChange={(ageValue) => this.setState({ ageValue })}
                        />
                        <Row className="rangeAge">
                          <Col id="age-filters">
                            <Row>
                              <Col className="separator">
                                <span className="grey">Minimum age</span>
                              </Col>
                              <Col>
                                <span className="grey">Maximum age</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="valueAge separator">
                                <span>{this.state.ageValue.min} </span>
                                <span>
                                  {this.state.ageValue.min < 2
                                    ? "year"
                                    : "years"}
                                </span>
                              </Col>
                              <Col className="valueAge">
                                <span>{this.state.ageValue.max} </span>
                                <span>
                                  {this.state.ageValue.max < 2
                                    ? "year"
                                    : "years"}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    )}
                </Col>
                <Col className="filter-bloc__text-placement" md={6}>
                  &nbsp;
                  <span className="grey">TYPE OF DIET</span>
                  <Row>
                    <Col>
                      &nbsp;
                      <span
                        id="diet-type__vegan"
                        className={`spanFilter roundedSpan ${
                          this.state.activeDietFilter === "diet-type__vegan" &&
                          "activeFilter"
                        }`}
                        onClick={(value) => {
                          this.selectDietFilter(value);
                          this.setState({ dietType: "Vegan" });
                        }}
                      >
                        <img
                          className="iconFilter"
                          alt="diet vegan"
                          src={
                            this.state.activeDietFilter === "diet-type__vegan"
                              ? activeVeganIcon
                              : veganIcon
                          }
                        />
                        Vegan
                      </span>
                    </Col>
                    <Col>
                      &nbsp;
                      <span
                        onClick={this.selectDietFilter}
                        id="diet-type__omnivore"
                        className={`spanFilter roundedSpan ${
                          this.state.activeDietFilter ===
                            "diet-type__omnivore" && "activeFilter"
                        }`}
                        onClick={(value) => {
                          this.selectDietFilter(value);
                          this.setState({ dietType: "Omnivore" });
                        }}
                      >
                        <img
                          className="iconFilter"
                          id="diet-type__omnivore"
                          alt="diet omnivore"
                          src={
                            this.state.activeDietFilter ===
                            "diet-type__omnivore"
                              ? activeOmnivoreIcon
                              : omnivoreIcon
                          }
                        />
                        Omnivore
                      </span>
                    </Col>
                    <Col>
                      &nbsp;
                      <span
                        onClick={this.selectDietFilter}
                        id="diet-type__vegetarian"
                        className={`spanFilter roundedSpan ${
                          this.state.activeDietFilter ===
                            "diet-type__vegetarian" && "activeFilter"
                        }`}
                        onClick={(value) => {
                          this.selectDietFilter(value);
                          this.setState({ dietType: "Vegetarian" });
                        }}
                      >
                        <img
                          className="iconFilter"
                          alt="diet vegetarian"
                          src={
                            this.state.activeDietFilter ===
                            "diet-type__vegetarian"
                              ? activeVeggieIcon
                              : veggieIcon
                          }
                        />
                        Vegetarian
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col className="filter-bloc__text-placement" md={6}>
                  &nbsp;
                  <span className="grey">NUMBER OF PLANTS PER WEEK</span>
                  <br />
                  {this.state.plantsRange && this.state.plantsValue && (
                    <Col>
                      <InputRange
                        maxValue={50}
                        minValue={0}
                        value={this.state.plantsValue}
                        onChange={(plantsValue) =>
                          this.setState({ plantsValue })
                        }
                      />
                      <Row className="rangeAge">
                        <Col id="age-filters">
                          <Row>
                            <Col className="separator">
                              <span className="grey">Minimum plants</span>
                            </Col>
                            <Col>
                              <span className="grey">Maximum plants</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="valueAge separator">
                              <span>{this.state.plantsValue.min} </span>
                              <span>
                                {this.state.plantsValue.min < 2
                                  ? "plant"
                                  : "plants"}
                              </span>
                            </Col>
                            <Col className="valueAge">
                              <span>{this.state.plantsValue.max} </span>
                              <span>
                                {this.state.plantsValue.max < 2
                                  ? "plant"
                                  : "plants"}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={2} className="clearBloc">
              &nbsp;
              <span
                onClick={() => {
                  const {
                    dietType,
                    plantsValue: diet,
                    ageValue: age,
                    plantsRange,
                    ageRange,
                  } = this.state;
                  const countries = this.props.countries;
                  const countryType = this.props.countryType;
                  const countryOfBirth =
                    countryType === "birth" ? countries : null;
                  const countryResidence =
                    countryType === "residence" ? countries : null;
                  this.props.triggerFilters(
                    countryResidence,
                    countryOfBirth,
                    dietType ? [dietType] : null,
                    JSON.stringify(diet) === JSON.stringify(plantsRange)
                      ? null
                      : diet,
                    JSON.stringify(age) === JSON.stringify(ageRange)
                      ? null
                      : age
                  );
                }}
                id="save_filters"
                className={`spanFilter roundedSpan`}
              >
                Submit
              </span>
              <span onClick={this.clearFilter} className="clearAll">
                Clear all
              </span>
            </Col>
          </Row>
        </div>
        <div id="filter-bar" className="mobile-block">
          <a className="footer-element" onClick={this.props.openModal}>
            <img
              className="iconFilter"
              alt="information"
              src={
                this.state.activeFilter === "how-it-works"
                  ? howItWorksOrangeIcon
                  : howItWorksGreyIcon
              }
            />
            How it works
          </a>
          <a className="footer-element" onClick={this.props.openFiltersModal}>
            <img
              className="iconFilter"
              alt="information"
              src={
                this.state.activeFilter === "filters"
                  ? filterOrangeIcon
                  : filterGreyIcon
              }
            />
            Filters
          </a>
          {/* <div className="footer-element">
            <img
              className="iconFilter"
              alt="Share website"
              src={shareGreyIcon}
            />
            <ShareLink link={window.location.href}>
              {(link) => (
                <a href={link} target="_blank">
                  Share
                </a>
              )}
            </ShareLink>
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
