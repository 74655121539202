import React, { Component } from "react";
import ChatInput from "./ChatInput";
import ChatMessage from "./ChatMessage";
import defaultLogo from "../../public/logoFooter.png";
import { Link } from "react-router-dom";
import { CHAT_URL, API_URL } from "../../shared/api";
import axios from "axios";
import "./Chat.scss";

class Chat extends Component {
  state = {
    name: "",
    messages: [],
    displayName: true,
    lastIndex: null,
    messagesLimit: null,
  };

  ws = new WebSocket(CHAT_URL);

  componentDidMount() {
    this.getMessagesHistory();

    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.info("Connected to chat!");
    };

    this.ws.onmessage = (evt) => {
      // on receiving a message, add it to the list of messages
      const message = JSON.parse(evt.data);
      this.addMessage(message);
    };

    this.ws.onclose = () => {
      console.info("Disconnected from chat!");
      // automatically try to reconnect on connection loss
      this.setState(
        {
          ws: new WebSocket(CHAT_URL),
        },
        () => console.info("Reconnected to chat!")
      );
    };
  }

  getMessagesHistory = async () => {
    const { data } = await axios.post(`${API_URL}/chat-history`, {});
    this.setState({
      messages: data.Items || [],
      ScannedCount: data.ScannedCount,
      messagesLimit: data.MESSAGES_LIMIT,
    });
    this.scrollToBottomChat();
  };

  loadMessagesHistory = async () => {
    const lastIndex = this.state.messages[0].id;
    const { data } = await axios.post(`${API_URL}/chat-history`, {
      ExclusiveStartKey: lastIndex,
    });
    this.setState(
      (state) => ({
        messages: [...data.Items, ...state.messages],
        ScannedCount: data.ScannedCount,
      }),
      () => this.scrollToDiv(`message__${this.state.messagesLimit - 1}`)
    );
  };

  displayName = () => {
    if (this.state.name)
      this.setState(
        {
          displayName: false,
          id: this.state.name + new Date().getTime(),
        },
        () => this.scrollToBottomChat()
      );
    else
      this.setState(
        {
          name: "Name",
          displayName: false,
          id: "Name" + new Date().getTime(),
        },
        () => this.scrollToBottomChat()
      );
  };

  addMessage = (message) => {
    message.message.length > 0 &&
      this.setState(
        (state) => ({ messages: [...state.messages, message] }),
        () => this.scrollToBottomChat()
      );
  };

  scrollToDiv = (divId) => document.getElementById(divId).scrollIntoView();

  scrollToBottomChat = () => {
    const element = document.getElementById("messages");
    element.scrollTop = element.scrollHeight;
  };

  submitMessage = (messageString) => {
    // on submitting the ChatInput form, send the message, add it to the list and reset the input
    const message = {
      name: this.state.name,
      message: messageString,
      id: this.state.id,
      date: new Date(),
    };
    this.ws.send(JSON.stringify(message));
  };

  render() {
    return (
      <div className="container-main">
        <div className="chat-header">
          <img src={defaultLogo} alt="gutzilla" className="chat-logo" />
          <ul className="list-group list-group-horizontal pointer">
            <Link to="/" className="link__like">
              <li className="list-group-item">Analysis</li>
            </Link>
            <li className="list-group-item active-tab">Chat</li>
          </ul>
        </div>

        <div className="container-chat">
          {this.state.displayName && (
            <div className="type-name-input">
              <input
                autoComplete="off"
                type="text"
                id={"name"}
                placeholder={"Enter your name..."}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                className="form-control"
              />
              <button className="button_base" onClick={this.displayName}>
                Save
              </button>
            </div>
          )}

          <div className="messages-list" id="messages">
            {this.state.messages.length >= this.state.messagesLimit &&
              this.state.messages.length < this.state.ScannedCount - 1 && (
                <span
                  className="horizontal-centered button-like"
                  onClick={this.loadMessagesHistory}
                >
                  Load more...
                </span>
              )}
            {this.state.messages.map((message, index) => (
              <ChatMessage
                key={index}
                index={index}
                message={message.message}
                name={message.name}
                date={message.date}
                isSelf={this.state.id === message.id}
              />
            ))}
          </div>

          {!this.state.displayName && (
            <ChatInput
              key={this.state.name}
              ws={this.ws}
              onSubmitMessage={(messageString) =>
                this.submitMessage(messageString)
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export default Chat;
