import React from "react";
import * as d3 from "d3";
import { connect } from "react-redux";
import "./Scatterplot.scss";
import { COLORS, DEFAULT_COLOR } from "../../shared/constants";
import {
  myColor,
  getColorsByRule,
  getLegendTitleByRule,
  getLegendValueByColor,
  getColorByRule,
  getComparisonValueByRule,
  getWidthValuesByRule,
  getMostReccurentCountries,
} from "../../shared/compute";
import { getDomainField } from "../../shared/utils";
import gemGif from "../../public/gif_microbiome.gif";

const mapStateToProps = (state, action) => {
  return {
    countries: state.DispatchCountriesListReducer,
    countryType: state.DispatchFiltreMapReducer.countryType,
    barSelected: state.DispatchFiltreBarChartReducer.barSelected,
    age: state.DispatchFiltreAgeReducer.age,
  };
};

let barSelected,
  isLoading = false;

class Scatterplot extends React.Component {
  constructor(props) {
    super(props);
    this.countrySelected = [];
    this.state = {
      isLoading: false,
      countResult: 0,
      taxonomy: null,
      pcoa: null,
      countries: "",
      typeCountry: null,
      barSelected: "",
      age: "",
      data: [],
      isCalled: false,
      lastIndex: null,
      tabsButtonActive: null,
      mostRecurrentsCountries: [],
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.tabsButtonActive !== this.state.tabsButtonActive) {
      return true;
    }
    if (nextProps.microbiomeData !== this.props.microbiomeData) {
      if (document.getElementById("0")) {
        document.getElementById("0").remove();
        if (document.getElementById("tooltip")) {
          document.getElementById("tooltip").remove();
        }
      }

      if (nextProps.microbiomeData) {
        this.setState({ data: nextProps.microbiomeData }, () => {
          this.computeMicrobiomeData();
        });
      }

      return true;
    }

    if (nextProps.barSelected !== this.props.barSelected) {
      barSelected = nextProps.barSelected;
      this.setState({ tabsButtonActive: null }, () =>
        this.computeMicrobiomeData()
      );
      return true;
    }

    if (nextProps.isLoading !== isLoading) {
      isLoading = nextProps.isLoading;
      return true;
    }

    return false;
  }

  computeMicrobiomeData = () => {
    const { data } = this.state;
    if (data && data.length > 0) {
      this.computeMostRecurrentCountries();
      let pcoaData;
      setTimeout(() => {
        const pcoaFullData = barSelected
          ? data
              // update data that are not detected by having their rank value to 100
              .map((el) => {
                const microbiome = el.alr.find(
                  (alr) => alr.genus === barSelected
                );
                return microbiome &&
                  microbiome.rank > 0 &&
                  microbiome.rank < 100
                  ? el
                  : { ...el, alr: { ...el.alr, rank: 100 } };
              })
          : data;

        pcoaData = pcoaFullData
          // filter missing data microbiomes
          .filter(
            (el) => el.pcoa && el.pcoa[0] && el.pcoa[0].axe1 && el.pcoa[0].axe2
          )
          // transform string in float
          .map((el) => {
            el.pcoa.axe1 = parseFloat(el.pcoa[0].axe1);
            el.pcoa.axe2 = parseFloat(el.pcoa[0].axe2);
            return el;
          });
        if (document.getElementById("0")) {
          document.getElementById("0").remove();
          if (document.getElementById("tooltip")) {
            document.getElementById("tooltip").remove();
          }
        }
        this.scatterPlot(pcoaData);
      }, 0);
    }
  };

  handleTabs(e) {
    const tabsButtonActive =
      this.state.tabsButtonActive === e.target.id ? null : e.target.id;
    this.setState({ tabsButtonActive }, () => this.computeMicrobiomeData());
  }

  computeMostRecurrentCountries = () => {
    return new Promise((resolve, _) => {
      setTimeout(() => {
        const countries = this.state.data
          .map((e) => e.metadata)
          .map((el) => el.COUNTRY_RESIDENCE);
        const recurrencePerCountry = getMostReccurentCountries(countries);
        const mostRecurrentsCountries = Object.entries(recurrencePerCountry)
          .sort(([, a], [, b]) => {
            if (a > b) return -1;
            if (a < b) return 1;
            return 0;
          })
          .map((el) => el[0])
          .slice(0, 10);
        this.setState({ mostRecurrentsCountries }, () => resolve());
      }, 0);
    });
  };

  scatterPlot = (pcoaData) => {
    let { svg } = this.state;
    const _this = this;
    if (this.state.data) {
      const margin = { top: 20, right: 20, bottom: 50, left: 60 },
        width = 680 - margin.left - margin.right,
        height = 650 - margin.top - margin.bottom;
      svg = d3
        .select("#my_data")
        .append("svg")
        .attr("id", (_, i) => i)
        .attr("viewBox", `0 0 790 750`)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
      const maxXdomain = getDomainField(
        pcoaData.map((el) => el.pcoa),
        "axe1",
        "max"
      );
      const minXdomain = getDomainField(
        pcoaData.map((el) => el.pcoa),
        "axe1",
        "min"
      );
      const maxYdomain = getDomainField(
        pcoaData.map((el) => el.pcoa),
        "axe2",
        "max"
      );
      const minYdomain = getDomainField(
        pcoaData.map((el) => el.pcoa),
        "axe2",
        "min"
      );
      const x = d3
        .scaleLinear()
        .domain([minXdomain, maxXdomain])
        .range([0, width]);
      const y = d3
        .scaleLinear()
        .domain([minYdomain, maxYdomain])
        .range([height, 0]);
      const xAxis = d3.axisBottom(x).ticks(3, "f").tickSizeInner([-height]);
      const yAxis = d3.axisLeft(y).ticks(3, "f").tickSizeInner([-width]);
      const rangeX = d3.extent(pcoaData, (d) => d.pcoa.axe1);
      const rangeY = d3.extent(pcoaData, (d) => d.pcoa.axe2);
      x.domain(rangeX).nice();
      y.domain(rangeY).nice();

      svg
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis /*.ticks(3, "f").tickSizeInner([-height])*/)
        .append("text")
        .attr("class", "label")
        .attr("x", width)
        .attr("y", -6)
        .style("text-anchor", "end");

      svg
        .append("text")
        .attr("x", 500)
        .attr("y", -2)
        .style("font-size", "15px")
        .style("font-weight", "bold")
        .style("fill", "#51BAAA")
        .text(`${pcoaData.length} results`);

      svg
        .append("g")
        .attr("class", "y axis")
        .call(yAxis /*.ticks(3, "f").tickSizeInner([-width])*/)
        .append("text")
        .append("text")
        .attr("class", "label")
        .attr("transform", "rotate(-90)")
        .attr("y", 6)
        .attr("dy", ".71em")
        .style("text-anchor", "end")
        .text("Sepal Length (cm)");

      svg.selectAll("line").style("stroke", "#D3D3D3");
      if (pcoaData.length > 0) {
        svg.append("defs").append("svg:clipPath")
          .attr("id", "clip")
          .append("svg:rect")
          .attr("id", "clip-rect")
          .attr("x", "0")
          .attr("y", "0")
          .attr('width', width)
          .attr('height', height);
        svg
          .append("g").attr("clip-path", "url(#clip)")
          .selectAll(".dot")
          .data(pcoaData)
          .enter()
          .append("circle")
          .attr("class", "dot")
          .attr("r", 2)
          .style("stroke", "none")
          .attr("cx", (d) => x(d.pcoa.axe1))
          .attr("cy", (d) => y(d.pcoa.axe2))
          .style("fill", function (d) {
            if (_this.state.tabsButtonActive) {
              const colorsFn = getColorByRule[_this.state.tabsButtonActive];
              const colorValue = getComparisonValueByRule(
                _this.state.tabsButtonActive,
                d
              );
              return (
                colorsFn(colorValue, _this.state.mostRecurrentsCountries) ||
                DEFAULT_COLOR
              );
            }
            if (barSelected) {
              if (!Array.isArray(d.alr)) {
                d.alr = [d.alr];
              }
              const microbiome = d.alr.find((el) => el.genus === barSelected);
              return myColor(microbiome && microbiome.rank) || DEFAULT_COLOR;
            }
            return DEFAULT_COLOR;
          });
        if (barSelected || _this.state.tabsButtonActive) {
          const colors = _this.state.tabsButtonActive
            ? getColorsByRule[_this.state.tabsButtonActive]
            : COLORS;
          let reversedColors = [...colors];
          if (_this.state.tabsButtonActive === "country") {
            const _clone = [...reversedColors].slice(
              0,
              _this.state.mostRecurrentsCountries.length
            );
            reversedColors = [..._clone];
          }
          reversedColors.reverse();
          const legend = svg
            .selectAll(".legend")
            .data(reversedColors)
            .enter()
            .append("g")
            .attr("class", "legend")
            .attr("transform", (_, i) => "translate(0," + i * 18 + ")");
          legend
            .append("rect")
            .data(reversedColors)
            .attr("x", width + 5)
            .attr("y", 8 + 70)
            .attr("width", 25)
            .attr("height", 18)
            .style("fill", (d) => d);
          legend
            .data(reversedColors)
            .append("text")
            .attr("x", (_, i) =>
              getWidthValuesByRule(_this.state.tabsButtonActive, i, width)
            )
            .attr("y", 18 + 70)
            .attr("dy", ".35em")
            .style("text-anchor", "end")
            .text((d) =>
              getLegendValueByColor(
                _this.state.tabsButtonActive,
                d,
                _this.state.mostRecurrentsCountries
              )
            )
            .style("font-size", 11);
        }
        svg
          .append("text")
          .attr("width", 100)
          .attr("class", "legend-bars")
          .attr("height", 15)
          .attr("font-size", "14px")
          .attr("y", height + 30)
          .attr("x", width / 2)
          .attr("fill", "rgb(211, 211, 211)")
          .text("pcoa1");

        svg
          .append("text")
          .attr("width", 100)
          .attr("height", 15)
          .attr("y", 20 - margin.left)
          .attr("x", -height / 1.7)
          .attr("font-size", "14px")
          .attr("fill", "rgb(211, 211, 211)")
          .attr("transform", "rotate(-90)")
          .text("pcoa2");
      }
    }
  };

  render() {
    return (
      <div className="graph-padding">
        <span className="graphLabel">
          <div className="title">
            Microbiome Map
            <span onClick={() => this.props.getInfo(5)}>
              <i className="infoIcon material-icons">info</i>
            </span>
          </div>
          {(barSelected || this.state.tabsButtonActive) && (
            <div className="legends-label">
              <div className="rank" id="rank__map">
                {this.state.tabsButtonActive
                  ? getLegendTitleByRule[this.state.tabsButtonActive]
                  : "Rank"}
              </div>
            </div>
          )}
        </span>

        {!isLoading && (
          <div className="tabs">
            <button
              className={
                this.state.tabsButtonActive === "age" ? "active" : undefined
              }
              onClick={(e) => this.handleTabs(e)}
              id="age"
            >
              Age
            </button>
            <button
              className={
                this.state.tabsButtonActive === "country" ? "active" : undefined
              }
              onClick={(e) => this.handleTabs(e)}
              id="country"
            >
              Country
            </button>
            <button
              className={
                this.state.tabsButtonActive === "diet" ? "active" : undefined
              }
              onClick={(e) => this.handleTabs(e)}
              id="diet"
            >
              Diet
            </button>
          </div>
        )}

        <div className="graphContainer loading-block">
          {isLoading ? (
            <img src={gemGif} className="loading-gif" alt="Loading..." />
          ) : (
            <div id="my_data"></div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(Scatterplot);
