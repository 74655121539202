import React from "react";
import * as d3 from "d3";
import { connect } from "react-redux";
import gemGif from "../../public/gif_microbiome.gif";
import { dispatch_bars_filter } from "../Actions/DispatchFiltreBarChartActions";
import { dispatch_map_filter } from "../Actions/DispatchFiltreMapActions";
import { COLORS } from "../../shared/constants";
import { getMicrobiomeLabel } from "../../shared/utils";
import {
  myColor,
  getAllMeans,
  getDensityList,
  getMicrobiomesPercentages,
  getValuesByMicrobiome,
  kernelDensityEstimator,
  kernelEpanechnikov,
} from "../../shared/compute";
import "./BarChart.scss";
import microbiomeLogo from "../../public/logo_modal.png";

const mapDispatchToProps = {
  dispatch_bars_filter,
  dispatch_map_filter,
};

let timeout,
  isLoading = false;

const margin = { top: 170, right: 30, bottom: 50, left: 90 },
  width = 650 - margin.left - margin.right,
  height = 660 - margin.top - margin.bottom;

const mapStateToProps = (state, action) => {
  return {
    countries: state.DispatchCountriesListReducer,
    countryType: state.DispatchFiltreMapReducer.countryType,
    barSelected: state.DispatchFiltreBarChartReducer.barSelected,
    age: state.DispatchFiltreAgeReducer.age,
  };
};
class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBacterium: { key: "", color: "" },
      data: [],
      isCalled: false,
      countResult: 0,
      isLoading: false,
      timeout: null,
      lastIndex: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.microbiomeData !== this.props.microbiomeData) {
      if (document.getElementById("bar_chart")) {
        document.getElementById("bar_chart").remove();
      }
      this.setState({ data: nextProps.microbiomeData }, () =>
        this.computeMicrobiomeData()
      );
      return true;
    }

    if (nextProps.isLoading !== isLoading) {
      isLoading = nextProps.isLoading;
      return true;
    }

    return false;
  }

  computeMicrobiomeData = () => {
    if (this.props.microbiomeData && this.props.microbiomeData.length > 0) {
      const alrData = this.props.microbiomeData.map((el) => el.alr);
      const barsValue = getValuesByMicrobiome(alrData);
      const allMeans = getAllMeans(barsValue).sort(
        (prev, next) => prev.mean - next.mean
      );
      const microbiomesPercentages = getMicrobiomesPercentages(
        alrData,
        alrData.length
      );
      this.barchart(barsValue, allMeans, microbiomesPercentages);
    }
  };

  barchart(barsValue, allMeans, microbiomesPercentages) {
    let { svg } = this.state;
    const that = this;

    svg = d3
      .select("#my_dataviz")
      .append("div")
      .attr("id", "bar_chart")
      .append("svg")
      .attr("viewBox", `-5 30 720 650`)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    if (barsValue && allMeans && microbiomesPercentages) {
      // Add X axis
      const x = d3.scaleLinear().domain([-10, 120]).range([0, width]);
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .call(
          d3.axisBottom(x).tickValues([1, 25, 50, 75, 100]).tickSize(-height)
        )
        .select(".domain")
        .remove();

      // Add X axis label:
      svg
        .append("text")
        .attr("text-anchor", "end")
        .attr("x", width / 2)
        .attr("y", height + 40)
        .attr("font-size", "14px")
        .attr("fill", "rgb(211, 211, 211)")
        .style("transform", "translate(55px)")
        .text("participant gut microbiome rank");

      // Add Y axis label:
      svg
        .append("text")
        .attr("width", 100)
        .attr("height", 15)
        .attr("y", 8 - margin.left)
        .attr("x", -height / 1.7)
        .attr("font-size", "14px")
        .attr("fill", "rgb(211, 211, 211)")
        .attr("transform", "rotate(-90)")
        .text("microbial genus");

      // Create a Y scale for densities
      const y = d3.scaleLinear().domain([0, 0.25]).range([height, 0]);

      // Create the Y axis for names
      const yName = d3
        .scaleBand()
        .domain(allMeans.map((el) => el.key))
        .range([0, height])
        .paddingInner(1);
      const yValue = d3
        .scaleBand([0, 0])
        .domain(
          allMeans.map(
            (microbiome) => `${microbiomesPercentages[microbiome.key]} %`
          )
        )
        .range([0, height])
        .paddingInner(1);
      svg
        .append("g")
        .call(d3.axisLeft(yName).tickSize(0))
        .select(".domain")
        .remove();

      svg
        .append("g")
        .call(d3.axisRight(yValue).tickSize(width - 25))
        .select(".domain")
        .remove()
        .select("line")
        .remove();

      d3.selectAll(".tick")
        .on("mouseenter", function (d) {
          const microbiome = d;
          const microbiomeLabel = getMicrobiomeLabel(microbiome);
          if (microbiomeLabel) {
            clearTimeout(timeout);
            d3.select("#barchart") && d3.select("#barchart").remove();

            const div = d3
              .select("#my_dataviz")
              .append("div")
              .attr("class", "tooltip")
              .attr("id", "barchart")
              .style("opacity", 0.9)
              .style("pointer-events", "none")
              .style("display", "block");

            div
              .html(
                `
                <div class='modal-tooltip'>
                  <div class='image-modal'> 
                    <img src='${microbiomeLogo}' alt='microbiome'/>
                  </div>
                  <div class='tooltip-content'>
                    ${microbiome} <br/>
                    ${microbiomeLabel}
                  </div>
                </div>
              `
              )
              .style("left", "0")
              .style("top", "0")
              .style("font-size", "11px");
          }
        })
        .on("mouseleave", () => {
          clearTimeout(timeout);
          timeout = setTimeout(function () {
            d3.selectAll("#barchart").remove();
          }, 1000);
        });

      // Compute kernel density estimation for each column:
      const kde = kernelDensityEstimator(kernelEpanechnikov(7), x.ticks(40)); // increase this 40 for more accurate density.

      // compute density list
      const allDensity = getDensityList(barsValue, kde);

      // Add areas (density)
      svg
        .selectAll("areas")
        .data(allDensity)
        .enter()
        .append("path")
        .attr(
          "transform",
          (d) => "translate(0," + (yName(d.key) - height) + ")"
        )
        .attr("fill", (d, index) => {
          let microbiome = allDensity[index].key;
          const { selectedBacterium: bacterium } = that.state;
          const microbiomeData = allMeans.find((el) => el.key === d.key);
          if (bacterium.key === microbiome) {
            that.setState({
              selectedBacterium: {
                key: microbiome,
                color: myColor(microbiomeData.mean),
              },
            });

            return "#f8a538";
          } else {
            return myColor(microbiomeData.mean);
          }
        })
        .attr("id", ({ key }) => key)
        .datum((d) => d.density)
        .attr("opacity", 0.8)
        .attr("stroke", "#000")
        .attr("stroke-width", 0.1)
        .attr(
          "d",
          d3
            .line()
            .curve(d3.curveBasis)
            .x((d) => x(d[0]))
            .y((d) => y(d[1]))
        )
        .on("click", function (d, index) {
          const microbiome = allDensity[index].key;
          const { selectedBacterium: bacterium } = that.state;
          if (bacterium.key === microbiome) {
            d3.select(`#${bacterium.key}`).style("fill", `${bacterium.color}`);
            that.setState({
              selectedBacterium: { key: null, color: null },
            });
            return that.props.dispatch_bars_filter();
          } else if (bacterium.key) {
            d3.select(`#${bacterium.key}`).style("fill", `${bacterium.color}`);
          }
          that.setState({
            selectedBacterium: { key: microbiome, color: this.style.fill },
          });
          d3.select(this).style("fill", "#f8a538");
          that.props.dispatch_bars_filter(microbiome);
        });

      const legend = svg
        .selectAll(".legend")
        .append("div")
        .attr("class", "legend-percentages-ladder")
        .data(COLORS.reverse())
        .enter()
        .append("g")
        .attr("transform", (_, i) => `translate(0, ${i * 18})`);
      legend
        .append("rect")
        .data(COLORS)
        .attr("x", width + 20)
        .attr("y", 8)
        .attr("width", 25)
        .attr("height", 18)
        .style("margin-left", 40)
        .style("fill", (d) => d);
      legend
        .data(COLORS.reverse())
        .append("text")
        .attr("x", (d, i) => {
          switch (i) {
            case 4:
              return width + 55;
            case 3:
              return width + 70;
            case 2:
              return width + 76;
            case 1:
              return width + 82;
            case 0:
              return width + 89;

            default:
              return width + 72;
          }
        })
        .attr("y", 22)
        .style("color", "#6c7eaa")
        .style("text-anchor", "end")
        .text((d) => {
          switch (d) {
            case COLORS[4]:
              return "1";
            case COLORS[3]:
              return "2 - 3";
            case COLORS[2]:
              return "4 - 10";
            case COLORS[1]:
              return "11 - 30";
            case COLORS[0]:
              return "31 - 100";

            default:
              return "";
          }
        })
        .style("font-size", 11);
    }
  }
  render() {
    return (
      <div className="graph-padding">
        <span className="barchart-label">
          <div className="title">
            Microbiome profiles
            <span onClick={() => this.props.getInfo(4)}>
              <i className="infoIcon material-icons">info</i>
            </span>
          </div>
          <div className="legends-label">
            <div className="subtitle">
              % of <br />
              detected <br /> samples
            </div>
            <div className="rank">
              Median <br />
              Rank
            </div>
          </div>
        </span>
        <div className="graphContainer loading-block">
          {isLoading ? (
            <img src={gemGif} className="loading-gif" alt="Loading..." />
          ) : (
            <div id="my_dataviz"></div>
          )}
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BarChart);
