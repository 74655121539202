import React, { Component } from "react";
import qs from "query-string";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  LinkedinIcon,
  FacebookIcon,
  TwitterIcon,
} from "react-share";
import "./ShareButtons.scss";

class ShareButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: window.location.href,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.countries !== this.props.countries ||
      nextProps.age !== this.props.age ||
      (nextProps.countryType !== this.props.countryType &&
        this.props.countryType) ||
      nextProps.barSelected !== this.props.barSelected
    ) {
      let parseObject = {
        countryType: this.props.countryType,
        countries: this.props.countries,
        maxAge: this.props.age ? this.props.age.max : undefined,
        minAge: this.props.age ? this.props.age.min : undefined,
        bacteria: this.props.barSelected ? this.props.barSelected : undefined,
      };
      let parsed = qs.stringify(parseObject);
      this.setState({
        url: `${window.location.href}?${parsed}`,
      });
    }
    if (
      nextProps.countries !== undefined &&
      nextProps.age !== undefined &&
      nextProps.countryType !== undefined
    )
      return false;
    else return true;
  }

  render() {
    return (
      <div className="graphs-grid shareButtons">
        <LinkedinShareButton
          url={this.state.url}
          quote={"Microbiote"}
          className="button"
          beforeOnClick={this.share}
        >
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
        <FacebookShareButton
          url={this.state.url}
          quote={"Microbiote"}
          className="button"
          beforeOnClick={this.share}
        >
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <TwitterShareButton
          url={this.state.url}
          quote={"Microbiote"}
          className="button"
          beforeOnClick={this.share}
        >
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </div>
    );
  }
}

export default ShareButtons;
