import { FILTRE_DISPATCH_MAP, RESETMAP } from "../Constants/ActionsTypes";

export const dispatch_map_filter = (countryType) => {
  return {
    type: FILTRE_DISPATCH_MAP,
    countryType,
  };
};

export const reset_map = (message) => {
  return {
    type: RESETMAP,
    message,
  };
};
