import { combineReducers } from "redux";
import DispatchFiltreBarChartReducer from "./DispatchFiltreBarChartReducer";
import DispatchFiltreMapReducer from "./DispatchFiltreMapReducer";
import DispatchFiltreAgeReducer from "./DispatchFiltreAgeReducer";
import DispatchCountriesListReducer from "./DispatchCountriesListReducer";

export default combineReducers({
  DispatchFiltreBarChartReducer,
  DispatchFiltreMapReducer,
  DispatchFiltreAgeReducer,
  DispatchCountriesListReducer,
});
