export const ROUNDED_DIVISOR = 1000;

export const CATEGORIES = [
  "Roseburia",
  "Bacteroides",
  "Faecalibacterium",
  "Prevotella",
  "Ruminococcus",
  "Blautia",
  "Parabacteroides",
  "Coprococcus",
  "Akkermansia",
  "Bifidobacterium",
];

export const COLORS = [
  "#fde725", // 1
  "#6dcd59", // 2 - 3
  "#1f9e89", // 4 - 10
  "#482878", // 10 - 30
  "#440155", // 30 - 100
];

export const DIET_COLORS = [
  "#fde725", // omnivore
  "#1f9e89", // vegetarian
  "#440155", // vegan
];

export const AGE_COLORS = [
  "#fde725", // 60-69
  "#6dcd59", // 50-60
  "#1f9e89", // 40-49
  "#482878", // 30-39
  "#440155", // 20-29
];

export const COUNTRY_COLORS = [
  "#fde725", // clair
  "#b4de2c",
  "#6dcd59",
  "#35b779",
  "#1f9e89",
  "#26828e",
  "#31688e",
  "#3e4a89",
  "#482878",
  "#440154", //foncé
];

export const DEFAULT_COLOR = "#bfbfbf";

export const microbiomeLabels = {
  Bacteroides:
    "Bacteroides genera is the most dominant genera in the gut microbiome. Within this genera, numerous bacterial species such as Bacteroides vulgatus, Bacteroides dorei and Bacteroides thetaiotamicron. Their role consists in breaking down food and producing valuable nutrients and energy that the body needs.",
  Faecalibacterium:
    "Faecalibacterium is the most prevalent genera in the human gut microbiome. The most famous species called Faecalibacterium prauntizii has been considered as key element for human health, when its population is altered (decreased), it favors inflammatory diseases.  Faecalibacterium is very crucial in the production of short chain fatty acids, especially butyrate, known to have anti-inflammatory effects during the fermentation of fibers.",
  Blautia:
    "Like Roseburia and Ruminococcus, Blautia harbor large enzymatic capacities to degrade complex dietary fibers. A particular species, Blautia hydrogenotrophica, have the specific capacity to take dihydrogen present in the gut and to convert it to acetate, a short chain fatty acid.",
  Coprococcus:
    "Coprococcus is known to ferment complex carbohydrate to produce butyrate and acetate from lactate and formate. Coprococcus may be used as a microbial biomarker to assess the health of the human gastro-intestinal tract. A recent study showed that low level of Coprococcus was linked to anxiety and depression.",
  Parabacteroides:
    "They are prevalent in human gut population, but little is known about theirs functions in the microbial community.Like Bacteroide, Parabacteroides constitute a major groups of bacteria that inhabit the human body; forming the major part of the gastrointestinal (GI) microbiota. However, little is known about their functions.",
  Bifidobacterium:
    "Bifidobacterium are the most abundant genera in the gut microbiome, but they are known to decrease with age. Bifidobacterium break down glucose to produce lactate and acetate, to help your body perform essential functions such as digestion and by creating an acidic environment starving off harmful bacteria hence preventing causing harm. Some Bifidobacterium can be found also in fermented food.",
  Akkermansia:
    "Akkermansia is vastly represented by one specie called Akkermansia muciniphila. Akkermansia is known to protect intestinal cells. Some studies showed a positive role of Akkermansia to prevent metabolic disorders.",
  Prevotella:
    "Prevotella genera seems to be less abundant in the western population. Prevotella is thoughts to have particular enzymes that can degrade complexes fibers.",
  Ruminococcus:
    "Like Roseburia, Ruminococcus harbor large enzymatic capacities to degrade complex dietary fibers. Ruminococcus may also make a major contribution to plant cell wall breakdown in the large intestine.",
  Roseburia:
    "Roseburia species like Roseburia instestinalis are known to harbor large enzymatic capacities to degrade complex dietary fibers.  Roseburia also produces short-chain fatty acids, especially butyrate, which plays a role in immunity maintenance and anti-inflammatory properties.",
};
