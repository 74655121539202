import {
  FILTRE_DISPATCH_MAP,
  RESETMAP,
} from "../../src/components/Constants/ActionsTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case FILTRE_DISPATCH_MAP:
      return {
        ...state,
        countries: action.countries,
        countryType: action.countryType,
      };
    case RESETMAP:
      return {
        ...state,
        message: action.message,
      };
    default:
      return state;
  }
};
