/**
 * returns the API base URL following injected environment
 */
const getUrlByEnvironment = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return (
        process.env.REACT_APP_API_PRODUCTION_URL ||
        "https://d350gfdskyb1q6.cloudfront.net/prod"
      );
    case "staging":
      return (
        process.env.REACT_APP_API_STAGING_URL ||
        "https://vx9g4re10d.execute-api.eu-west-1.amazonaws.com/dev"
      );
    case "development":
      return (
        process.env.REACT_APP_API_DEVELOPMENT_URL ||
        "https://vx9g4re10d.execute-api.eu-west-1.amazonaws.com/dev"
      );
    default:
      return (
        process.env.REACT_APP_API_DEVELOPMENT_URL ||
        "https://vx9g4re10d.execute-api.eu-west-1.amazonaws.com/dev"
      );
  }
};

const getUrlChatByEnvironment = () => {
  switch (process.env.REACT_APP_ENV) {
    case "staging":
      return (
        process.env.REACT_APP_API_STAGING_WEBSOCKET_URL ||
        "wss://110a25rwg8.execute-api.eu-west-1.amazonaws.com/chat"
      );
    case "production":
      return (
        process.env.REACT_APP_API_PRODUCTION_WEBSOCKET_URL ||
        "wss://s27w1uuywc.execute-api.eu-west-1.amazonaws.com/chat"
      );
    case "development":
      return (
        process.env.REACT_APP_API_DEVELOPMENT_WEBSOCKET_URL ||
        "wss://110a25rwg8.execute-api.eu-west-1.amazonaws.com/chat"
      );
    default:
      return (
        process.env.REACT_APP_API_DEVELOPMENT_WEBSOCKET_URL ||
        "wss://110a25rwg8.execute-api.eu-west-1.amazonaws.com/chat"
      );
  }
};

export const API_URL = getUrlByEnvironment();
export const CHAT_URL = getUrlChatByEnvironment();
