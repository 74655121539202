import React, { Component } from "react";
import BarChart from "../BarChart/BarChart";
import Header from "../Header/Header";
import Map from "../Map/Map";
import ModalHeader from "../ModalHeader/ModalHeader";
import ModalFilters from "../ModalFilters/ModalFilters";
import Campaign from "../Campaign/Campaign";
import Filters from "../Filters/Filters";
import Footer from "../Footer/Footer";
import Scatterplot from "../Scatterplot/Scatterplot";
import AlphaDiversity from "../AlphaDiversity/AlphaDiversity";
import { dispatch_map_filter } from "../Actions/DispatchFiltreMapActions";
import { dispatch_age_filter } from "../Actions/DispatchFiltreAgeActions";
import { dispatch_bars_filter } from "../Actions/DispatchFiltreBarChartActions";
import { Col, Row } from "react-bootstrap";
import "./Analyse.scss";
import { connect } from "react-redux";
import qs from "query-string";
import axios from "axios";
import { API_URL } from "../../shared/api";
import { Link } from "react-router-dom";
import { HiOutlineChatAlt } from "react-icons/hi";
import ReactGA from "react-ga";
import ShareButtons from "../ShareButtons/ShareButtons";

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS;

ReactGA.initialize(trackingId);
ReactGA.pageview("/");

const mapStateToProps = (state, action) => {
  return {
    countries: state.DispatchCountriesListReducer,
    countryType: state.DispatchFiltreMapReducer.countryType,
    barSelected: state.DispatchFiltreBarChartReducer.barSelected,
    age: state.DispatchFiltreAgeReducer.age,
    message: state.DispatchFiltreMapReducer.message,
  };
};

const mapDispatchToProps = {
  dispatch_map_filter,
  dispatch_age_filter,
  dispatch_bars_filter,
};

class Analyse extends Component {
  constructor(props) {
    super(props);
    this.modalHeader = React.createRef();
    this.scrollDiv = React.createRef();
    this.modalFilters = React.createRef();
    this.mapComponent = React.createRef();

    this.state = {
      data: [],
      availableCountries: [],
      countriesSelected: null,
      countResult: 0,
      isLoading: false,
      isMapLoading: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.countries &&
      nextProps.countries !== this.state.countriesSelected
    ) {
      this.setState({ countriesSelected: nextProps.countries });
    }
    return true;
  }

  setCountriesSelection = (countriesSelected) => {
    this.setState({ countriesSelected });
  };

  scroll = () => {
    this.scrollDiv.current.scrollIntoView({ behavior: "smooth" });
  };

  openModalHeader = () => {
    this.modalHeader.current.triggerModal();
  };

  openModalFilers = (data) => {
    this.modalFilters.current.triggerModal();
  };

  getInfoModal = (page) => {
    this.modalHeader.current.goToPage(page);
  };

  getAvailableCountries = (type) => {
    axios
      .post(`${API_URL}/countries`, { type })
      .then((res) => {
        this.setState({
          availableCountries: [...new Set(res.data)].sort(),
          isMapLoading: false,
        });
      })
      .catch((err) => console.error(err));
  };

  componentDidMount() {
    this.getAvailableCountries(this.state.countryType);
    this.getDataByFilters();
    if (this.props.location.search) {
      const parsedObject = qs.parse(this.props.location.search);
      this.props.dispatch_age_filter(
        parsedObject.maxAge && parsedObject.minAge
          ? { max: parsedObject.maxAge, min: parsedObject.minAge }
          : undefined
      );
      this.props.dispatch_map_filter(
        parsedObject.countries,
        parsedObject.countryType
      );
      this.props.dispatch_bars_filter(parsedObject.bacteria);

      const age = {
        min: parseInt(parsedObject.minAge),
        max: parseInt(parsedObject.maxAge),
      };
      this.getDataByFilters(
        parsedObject.countryType === "residence"
          ? parsedObject.countries
          : null,
        parsedObject.countryType === "birth" ? parsedObject.countries : null,
        age
      );
    }
  }

  updateURLshareFb = () => {
    let parseObject = {
      countryType: this.props.countryType,
      countries: this.props.countries,
      maxAge: this.props.age ? this.props.age.max : undefined,
      minAge: this.props.age ? this.props.age.min : undefined,
      bacteria: this.props.barSelected ? this.props.barSelected : undefined,
    };
    let parsed = qs.stringify(parseObject);
    this.setState({
      shareUrlFb: `${window.location.href}?${parsed}`,
    });
  };

  /**
   * This function is recursive, it fetches by segments the whole data of microbiomes
   * @param {*} countryOfResidence
   * @param {*} countryOfBirth
   * @param {*} age
   */
  recursiveGetData = async (
    countryResidence,
    countryBirth,
    dietType,
    diet,
    age
  ) => {
    const countryOfResidence =
      countryResidence && countryResidence.length > 0 ? countryResidence : null;
    const countryOfBirth =
      countryBirth && countryBirth.length > 0 ? countryBirth : null;
    this.setState({ isLoading: true });
    const _this = this;
    const localState = {
      data: [],
      countResult: null,
      lastIndex: null,
    };

    try {
      (async function loop(
        countryOfResidence,
        countryOfBirth,
        dietType,
        diet,
        age,
        lastIndex = null
      ) {
        axios
          .post(`${API_URL}/data`, {
            countryOfResidence,
            countryOfBirth,
            dietType,
            diet,
            age,
            ExclusiveStartKey: lastIndex && {
              id: lastIndex,
            },
          })
          .then(({ data }) => {
            localState.data.push(
              ...data.Items.map((item) =>
                Object.assign({}, item, {
                  alr: item.alr.filter((el) => el.rank > 0 && el.rank < 100),
                })
              )
            );
            localState.countResult += data.Count;
            if (data.lastIndex && data.lastIndex.id) {
              loop(
                countryOfResidence,
                countryOfBirth,
                dietType,
                diet,
                age,
                data.lastIndex.id
              );
            } else {
              _this.setState({
                data: localState.data.filter(
                  (el) =>
                    el.pcoa && el.pcoa[0] && el.pcoa[0].axe1 && el.pcoa[0].axe2
                ),
                isLoading: false,
                countResult: localState.countResult,
              });
            }
          })
          .catch((error) => console.error("An error happened", error.message));
      })(countryOfResidence, countryOfBirth, dietType, diet, age);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  resetSelection = () => {
    this.setState({ countriesSelected: [] }, () =>
      this.mapComponent.current.resetSelectedCountries()
    );
  };

  getDataByFilters = (
    countryOfResidence = null,
    countryOfBirth = null,
    dietType = null,
    diet = null,
    age = null
  ) => {
    ReactGA.event({
      category: "Analysis",
      action: "Filters set in analysis page",
      label: JSON.stringify({
        countryOfResidence,
        countryOfBirth,
        dietType,
        diet,
        age,
      }),
    });
    this.recursiveGetData(
      countryOfResidence,
      countryOfBirth,
      dietType,
      diet,
      age
    );
  };

  render() {
    return (
      <div className="app">
        <Header openModal={this.openModalHeader} scroll={this.scroll} />
        <div className="bottom-information">
          <Row className="noMargin" ref={this.scrollDiv}>
            <Col>
              <Row>
                <Filters
                  {...this.props}
                  openModal={this.openModalHeader}
                  openFiltersModal={this.openModalFilers}
                  triggerFilters={this.getDataByFilters}
                  resetSelection={this.resetSelection}
                />
              </Row>
              <Row className="graphs-grid">
                <Col sm={12} md={6} className="borders">
                  <Map
                    ref={this.mapComponent}
                    {...this.props}
                    getInfo={this.getInfoModal}
                    getAvailableCountries={this.getAvailableCountries}
                    availableCountries={this.state.availableCountries}
                    countryType={this.state.countryType}
                    countriesSelected={this.state.countriesSelected}
                  />
                </Col>
                <Col sm={12} md={6} className="borders">
                  <AlphaDiversity
                    {...this.props}
                    getInfo={this.getInfoModal}
                    isLoading={this.state.isLoading}
                    microbiomeData={this.state.data}
                  />
                </Col>
                <Col sm={12} md={6} className="borders">
                  <BarChart
                    {...this.props}
                    getInfo={this.getInfoModal}
                    isLoading={this.state.isLoading}
                    microbiomeData={this.state.data}
                  />
                </Col>
                <Col sm={12} md={6} className="borders">
                  <Scatterplot
                    {...this.props}
                    getInfo={this.getInfoModal}
                    countResult={this.state.countResult}
                    isLoading={this.state.isLoading}
                    microbiomeData={this.state.data}
                  />
                </Col>
              </Row>
              <ShareButtons />
            </Col>
          </Row>
          <div className="background-cover">
            <Row id="campaign">
              <Campaign />
            </Row>
            <span className="mobile-background" />
            <ModalHeader ref={this.modalHeader}></ModalHeader>
            <ModalFilters
              {...this.props}
              ref={this.modalFilters}
              triggerFilters={this.getDataByFilters}
              setCountries={this.setCountriesSelection}
              countriesSelected={this.state.countriesSelected}
              availableCountries={this.state.availableCountries}
              resetSelection={this.resetSelection}
            ></ModalFilters>
            <Footer />
          </div>
        </div>
        <div className="chat-btn__placement">
          <Link to="/chat" className="chat-link">
            <HiOutlineChatAlt size={45} />
          </Link>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Analyse);
