import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import logoBlack from "../../public/ME_logo_black.png";
import logoMicrosetta from "../../public/microsetta.png";
import logoResearch from "../../public/research.png";
import Image from "react-bootstrap/Image";
import "./Footer.scss";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.termsConditions = React.createRef();
    this.privacyStatement = React.createRef();
    this.cookieStatement = React.createRef();
    this.state = {};
  }

  triggerTermsConditions = () => {
    this.termsConditions.current.triggerModal();
  };
  triggerPrivacyStatement = () => {
    this.privacyStatement.current.triggerModal();
  };
  triggerCookieStatement = () => {
    this.cookieStatement.current.triggerModal();
  };

  render() {
    return (
      <Row className="footer">
        <Col md={12}>
          <Row className="statements">
            <Col md={8} className="privacy-line">
              <a
                href="PRIVACY_STATEMENT.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                PRIVACY STATEMENT
              </a>
              <a
                href="COOKIE_STATEMENT.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                COOKIE STATEMENT
              </a>
              <a
                href="TERMS_AND_CONDITIONS.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                TERMS AND CONDITIONS
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="logos-line">
              <React.Fragment>
                <div>
                  <Image
                    className="logoFooter"
                    id="microbiome-img"
                    src={logoBlack}
                    alt="Gutzilla"
                    fluid
                  />
                  <Image
                    className="logoFooter"
                    src={logoResearch}
                    alt="Danone Nutricia"
                    fluid
                  />
                  <Image
                    className="logoFooter"
                    src={logoMicrosetta}
                    alt="The Microsetta initiative"
                    fluid
                  />
                </div>
              </React.Fragment>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default Footer;
