import React, { Component } from "react";
import worldJson from "../../world.json";
import * as d3 from "d3";
import { connect } from "react-redux";
import { dispatch_map_filter } from "../Actions/DispatchFiltreMapActions";
import { reset_map } from "../Actions/DispatchFiltreMapActions";
import { dispatch_age_filter } from "../Actions/DispatchFiltreAgeActions";
import { ClearCountry } from "../Actions/DispatchCountriesListActions";
import {
  addCountry,
  removeCountry,
} from "../Actions/DispatchCountriesListActions";
import "react-input-range/lib/css/index.css";
import qs from "query-string";
import Loader from "react-loader-spinner";
import "./Map.scss";
const mapStateToProps = (state, action) => {
  return {
    countries: state.DispatchFiltreMapReducer.countries,
    countryType: state.DispatchFiltreMapReducer.countryType,
    barSelected: state.DispatchFiltreBarChartReducer.barSelected,
    age: state.DispatchFiltreAgeReducer.age,
    message: state.DispatchFiltreMapReducer.message,
  };
};
const mapDispatchToProps = {
  dispatch_map_filter,
  dispatch_age_filter,
  addCountry,
  removeCountry,
  reset_map,
  ClearCountry,
};

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      activeFilter: "",
      ageFilter: "",
      countriesSelected: [],
      colors: [
        "#D4EAC7",
        "#C6E3B5",
        "#B7DDA2",
        "#A9D68F",
        "#9BCF7D",
        "#8CC86A",
        "#7EC157",
        "#77BE4E",
        "#70BA45",
        "#65A83E",
        "#599537",
        "#4E8230",
        "#437029",
        "#385D22",
        "#2D4A1C",
        "#223815",
      ],
      legendCellSize: 20,
      ageValue: { min: 0, max: 1 },
      ageRange: { min: 0, max: 1 },
      availableCountries: [],
      data: [],
      isCalled: false,
    };
  }

  shortCountryName = (country) => {
    return country
      .replace("Démocratique", "Dem.")
      .replace("République", "Rep.");
  };

  getColorIndex = (color) => {
    for (let i = 0; i < this.state.colors.length; i++) {
      if (this.state.colors[i] === color) {
        return i;
      }
    }
    return -1;
  };

  resetSelectedCountries = () => {
    let that = this;
    this.resetFilters();
    d3.select("#map svg")
      .selectAll("g")
      .selectAll("path")
      .style("fill", function (d) {
        let result = that.state.availableCountries.findIndex(
          (item) => item.toLowerCase() === d.properties.name.toLowerCase()
        );
        if (result === -1) {
          return "#D8F5F1";
        } else {
          return "#A8E4DC";
        }
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.countriesSelected !== this.state.countriesSelected) {
      this.setState({ countriesSelected: nextProps.countriesSelected }, () => {
        this.buildMap();
      });
    }

    if (nextProps.availableCountries !== this.state.availableCountries) {
      const parsedObject = qs.parse(this.props.location.search);
      this.setState(
        {
          availableCountries: nextProps.availableCountries,
          isLoading: false,
          isCalled: true,
          activeFilter: parsedObject.countryType
            ? parsedObject.countryType
            : "",
        },
        () => this.buildMap()
      );

      return true;
    }

    if (
      nextProps.countryType !== this.props.countryType &&
      this.props.countryType
    ) {
      if (document.getElementById("svg-map")) {
        this.props.reset_map("");
        this.props.ClearCountry([]);
        document.getElementById("svg-map").remove();
        if (document.getElementById("map-tooltip")) {
          document.getElementById("map-tooltip").remove();
        }
        const parsedObject = qs.parse(this.props.location.search);
        this.setState(
          {
            isLoading: true,
            countriesSelected: [],
            data: nextProps.data,
            isCalled: true,
            activeFilter: parsedObject.countryType
              ? parsedObject.countryType
              : "",
          },
          () => {
            this.props.getAvailableCountries(this.props.countryType);
          }
        );
      }
      return false;
    } else if (
      !this.props.countryType &&
      this.props.countryType !== nextProps.countryType
    ) {
      if (document.getElementById("svg-map")) {
        this.props.reset_map("");
        document.getElementById("svg-map").remove();
        if (document.getElementById("map-tooltip")) {
          document.getElementById("map-tooltip").remove();
        }
        this.props.ClearCountry([]);
        const parsedObject = qs.parse(this.props.location.search);
        this.setState(
          {
            isLoading: true,
            countriesSelected: [],
            data: nextProps.data,
            isCalled: true,
            activeFilter: parsedObject.countryType
              ? parsedObject.countryType
              : "",
          },
          () => {
            this.props.getAvailableCountries(this.props.countryType);
          }
        );
      }
    }
    return true;
  }

  buildMap = () => {
    let that = this;
    if (document.getElementById("svg-map")) {
      document.getElementById("svg-map").remove();
      if (document.getElementById("map-tooltip")) {
        document.getElementById("map-tooltip").remove();
      }
    }
    const width = 550,
      height = 450;
    var initX;
    var mouseClicked = false;
    var s = 1;
    var rotated = 90;

    var mouse;
    function zoomended() {
      if (s !== 1) return;
      rotated = rotated + ((d3.mouse(this)[0] - initX) * 300) / (s * width);
      mouseClicked = false;
    }

    function zoomed() {
      var t = [d3.event.transform.x, d3.event.transform.y];
      s = d3.event.transform.k;
      var h = 0;

      t[0] = Math.min(
        (width / height) * (s - 1),
        Math.max((width / 1.5) * (1 - s), t[0])
      );

      t[1] = Math.min(
        h * (s - 1) + h * s,
        Math.max(height * (1 - s) - h * s, t[1])
      );

      cGroup.attr("transform", "translate(" + t + ")scale(" + s + ")");
      d3.selectAll(".boundary").style("stroke-width", 1 / s);

      mouse = d3.mouse(this);

      if (s === 1 && mouseClicked) {
        rotateMap(mouse[0]);
        return;
      }
    }
    function rotateMap(endX) {
      projection.rotate([rotated + ((endX - initX) * 360) / (s * width), 0, 0]);
      cGroup.selectAll("path").attr("d", path);
    }

    var zoom = d3
      .zoom()
      .scaleExtent([1, 10])
      .on("zoom", zoomed)
      .on("end", zoomended);

    var div = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .attr("id", "map-tooltip")
      .style("opacity", 0.75);
    const svg = d3
      .select("#map")
      .append("svg")
      .attr("id", "svg-map")
      .attr("viewBox", `0 0 550 400`)
      .attr("class", "svg")
      .call(zoom);

    d3.select("#zoom-in").on("click", function () {
      // Smooth zooming
      zoom.scaleBy(svg.transition().duration(750), 1.3);
    });

    d3.select("#zoom-out").on("click", function () {
      // Ordinal zooming
      zoom.scaleBy(svg.transition().duration(750), 1 / 1.3);
    });

    const projection = d3.geoMercator().scale(1).translate([0, 0]);
    const path = d3.geoPath().pointRadius(2).projection(projection);
    const cGroup = svg.append("g");
    let promises = [];
    promises.push(worldJson);
    Promise.all(promises).then((values) => {
      const geojson = values[0];
      var b = path.bounds(geojson),
        s =
          0.9 /
          Math.max((b[1][0] - b[0][0]) / width, (b[1][1] - b[0][1]) / height),
        t = [
          (width - s * (b[1][0] + b[0][0])) / 2,
          (height - s * (b[1][1] + b[0][1])) / 2,
        ];
      projection.scale(s).translate(t);

      cGroup
        .selectAll("path")
        .data(geojson.features)
        .enter()
        .append("path")
        .attr("d", path)
        .attr("stroke", "#FFF")
        .attr("stroke-width", 0.3)
        .style("fill", function (d) {
          let result = that.state.availableCountries.findIndex(
            (item) => item.toLowerCase() === d.properties.name.toLowerCase()
          );
          if (result === -1) {
            return "#D8F5F1";
          } else {
            if (
              that.state.countriesSelected &&
              that.state.countriesSelected.includes(d.properties.name)
            ) {
              return "#57BAAA";
            } else {
              return "#A8E4DC";
            }
          }
        })
        .on("click", function (d) {
          let result = that.state.availableCountries.findIndex(
            (item) => item.toLowerCase() === d.properties.name.toLowerCase()
          );
          if (result !== -1 && that.props.countryType) {
            if ((that.state.countriesSelected || []).length === 0) {
              const selectedCountriesNames = [d.properties.name];
              that.props.addCountry(d.properties.name);
              d3.select(this).style("fill", "rgb(105, 179, 162)");
              div.transition().duration(1000).style("opacity", 0.75);
              div
                .html(
                  "<center ><bold> " +
                    d.properties.name +
                    "</bold><br/>" +
                    "" +
                    "&emsp;<a target='_blank' href='https://www.thdmi.org'> www.thdmi.org</a> &emsp;<br/><center>"
                )
                .style("left", d3.event.pageX - 50 + "px")
                .style("top", d3.event.pageY - 90 + "px")
                .style("background-color", "rgb(105, 179, 162)")
                .style("border-radius", "10px")
                .transition()
                .attr("delay", (d, i) => {
                  return setInterval(function () {
                    d3.selectAll(".tooltip").style("opacity", 0);
                  }, 5000);
                });
            } else {
              ///remove country
              if (that.state.countriesSelected.includes(d.properties.name)) {
                that.props.removeCountry(d.properties.name);
              } else {
                ///add another country
                that.setState({
                  countriesSelected: [
                    ...that.state.countriesSelected,
                    d.properties.name,
                  ],
                });
                that.props.addCountry(d.properties.name);
                d3.select(this).style("fill", "rgb(105, 179, 162)");
                div.transition().duration(1000).style("opacity", 0.75);
                div
                  .html(
                    "<center ><bold> " +
                      d.properties.name +
                      "</bold><br/>" +
                      "" +
                      "&emsp;<a target='_blank' href='https://www.thdmi.org'> www.thdmi.org</a> &emsp;<br/><center>"
                  )
                  .style("left", d3.event.pageX - 80 + "px")
                  .style("top", d3.event.pageY - 90 + "px")
                  .style("background-color", "rgb(105, 179, 162)")
                  .style("border-radius", "10px");
                /* .transition()
                    .attr("delay", (d, i) => {
                      return setInterval(function() {
                        d3.select(".tooltip").style("opacity", 0);
                      }, 10000);
                    });*/
                //.attr("duration", func(d,i){return 1000*(i+1)})
                //d3.select(this).style("fill", "rgb(156, 156, 156)");
                //d3.select(".tooltip").style("opacity", 0);
              }
            }
          }
        })
        .on("mouseover", function (d, i) {
          let result = that.state.availableCountries.findIndex(
            (item) => item.toLowerCase() === d.properties.name.toLowerCase()
          );
          if (result !== -1 && that.state.activeFilter) {
            if (
              that.state.countriesSelected &&
              that.state.countriesSelected.includes(d.properties.name)
            ) {
              d3.select(this).style("fill", "rgb(57, 184, 162)");
            } else {
              d3.select(this).style("fill", "rgb(54, 202, 178)");
            }
          }
        })
        .on("mouseout", function (d, i) {
          let result = that.state.availableCountries.findIndex(
            (item) => item.toLowerCase() === d.properties.name.toLowerCase()
          );
          if (result !== -1 && that.state.activeFilter) {
            if (
              that.state.countriesSelected &&
              that.state.countriesSelected.includes(d.properties.name)
            ) {
              d3.select(this).style("fill", "#57BAAA");
            } else {
              d3.select(this).style("fill", "#A8E4DC");
            }
          }
        })
        .attr("id", function (d) {
          return "code" + d.id;
        })
        .attr("class", "country");
    });

    /*************   add legend icon info  ************************** */

    svg
      .append("circle")
      .attr("r", 13)
      .attr("cx", 575)
      .attr("cy", 275)
      .attr("stroke", "black")
      .attr("fill", "#5CBCAD");
    svg
      .append("text")
      .attr("font-size", "15px")
      .attr("font-weight", "bold")
      .attr("height", 15)
      .attr("font-family", "system-ui")
      .attr("y", 280)
      .attr("x", 573)
      .attr("fill", "White")
      .text("i");
  };

  resetFilters = () => {
    this.setState({
      countriesSelected: [],
    });
  };
  selectFilter = (e) => {
    this.resetSelectedCountries();
    if (this.state.countriesSelected.length > 0)
      this.props.dispatch_map_filter(this.state.countriesSelected, e.target.id);
    this.setState({
      activeFilter: e.target.id,
    });
  };
  onClickOut = (e) => {
    if (this.state.ageFilter !== null)
      this.props.dispatch_age_filter(this.state.ageFilter);
  };
  handleAge = (e) => {
    if (e.target.id !== "my-input-range-id") {
      this.props.dispatch_age_filter(this.state.ageValue);
    }
  };
  render() {
    return (
      <div className="mapContainer">
        <span className="graphLabel">
          Citizen scientist World Map{" "}
          <span onClick={() => this.props.getInfo(2)}>
            <i className="infoIcon material-icons">info</i>
          </span>
        </span>

        <div className="graphContainer margin-first-graph center-loading">
          {this.state.isLoading ? (
            <Loader
              className="spinner-style"
              type="ThreeDots"
              color="#5CBCAD"
              height={100}
              width={100}
            />
          ) : (
            <div className="app" id="map">
              <div className="style-zoom">
                <div className="input-contain">
                  <button className="input-zoom" id="zoom-in">
                    +
                  </button>
                  <button className="input-zoom" id="zoom-out">
                    -
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Map);
