import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Chat.scss";

class ChatInput extends Component {
  static propTypes = {
    onSubmitMessage: PropTypes.func.isRequired,
  };
  state = {
    message: "",
  };

  render() {
    return (
      <div className="position-input">
        <form
          action="."
          onSubmit={(e) => {
            e.preventDefault();
            this.props.onSubmitMessage(this.state.message);
            this.setState({ message: "" });
          }}
          className="index-search-form"
        >
          <input
            type="textarea"
            placeholder={"Type your message here..."}
            value={this.state.message}
            onChange={(e) => this.setState({ message: e.target.value })}
            className="search-box"
          />
          <button className="button_base" name="submit" type="submit">
            Send
          </button>
        </form>
      </div>
    );
  }
}

export default ChatInput;
