import { ADD_COUNTRY, REMOVE_COUNTRY, CLEAR } from "../Constants//ActionsTypes";

export const addCountry = country => {
  return {
    type: ADD_COUNTRY,
    country
  };
};

export const removeCountry = country => {
  return {
    type: REMOVE_COUNTRY,
    country
  };
};
export const ClearCountry = empty => {
  return {
    type: CLEAR,
    empty
  };
};
