import {
  ADD_COUNTRY,
  REMOVE_COUNTRY,
  CLEAR,
} from "../components/Constants/ActionsTypes";

const countryReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_COUNTRY:
      return [...state, action.country];

    case REMOVE_COUNTRY:
      return [...state.filter((country) => country !== action.country)];
    case CLEAR:
      return [];
    default:
      return state;
  }
};

export default countryReducer;
