import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import "./Campaign.scss";

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (

          <Col className="campaign" md={{ span: 11, offset: 1 }}>
            <Row>
                <Col className="campaignContainer" md={5}>
                <span className="title">Participate in the Next</span>
                <p className="parag">THDMI has started its tour around the world: a first campaign has been done in US, the next one will be in Mexico. Stay tuned for participating to a future potential campaign in your country!
                </p>
                <span className="participate"><a target="_blank" href="https://www.thdmi.org" rel="noopener noreferrer">Participate to the campaign</a></span>
                </Col>
            </Row>
          </Col>

    );
  }
}

export default Campaign;
