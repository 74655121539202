import { microbiomeLabels } from "./constants";

export let dataUtils = [];

export const getMicrobiomeLabel = (microbiome) => microbiomeLabels[microbiome];

/**
 * This function returs the min or max value of an array of objects per filed
 * @param {*} data
 * @param {*} field
 * @param {*} domain
 */
export const getDomainField = (data, field, domain) => {
  switch (domain) {
    case "min":
      return data.reduce(
        (min, p) => (p[field] < min ? p[field] : min),
        data[0][field]
      );
    case "max":
      return data.reduce(
        (max, p) => (p[field] > max ? p[field] : max),
        data[0][field]
      );

    default:
      return;
  }
};
