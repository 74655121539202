import * as d3 from "d3";
import {
  AGE_COLORS,
  CATEGORIES,
  COLORS,
  DIET_COLORS,
  COUNTRY_COLORS,
  DEFAULT_COLOR,
} from "./constants";

/**
 * This function computes the kernel density estimation
 * @param {*} kernel
 * @param {*} X
 */
const kernelDensityEstimator = (kernel, X) => {
  return function (V) {
    return X.map(function (x) {
      return [
        x,
        d3.mean(V, function (v) {
          return kernel(x - v);
        }),
      ];
    });
  };
};

const kernelEpanechnikov = (k) => {
  return function (v) {
    return Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0;
  };
};

/**
 * This function computes the median of an array
 * @param {*} arr
 */
const median = (arr) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

/**
 * This function returns an array of density per microbiome
 * @param {*} data The data to analyse
 * @param {*} kde The kernel density estimator
 * @returns {Array} The density list
 */
const getDensityList = (data, kde) => {
  const allDensity = [];
  CATEGORIES.forEach((cat) => {
    allDensity.push({
      key: cat,
      density: kde(
        data.map((el, i) => {
          if (el[cat] && el[cat].rank) {
            return el[cat].rank;
          }
        })
      ),
    });
  });

  return allDensity;
};

const getAllMeans = (data) => {
  const allMeans = [];
  CATEGORIES.forEach((category) => {
    allMeans.push({
      key: category,
      mean: d3.mean(data, (d) => d[category] && d[category].rank),
    });
  });

  return allMeans;
};

const getValuesByMicrobiome = (data) => {
  const microbiomesData = [];
  data.forEach((microbiomes) => {
    const payload = {};
    microbiomes.forEach((microbiome) => {
      payload[microbiome.genus] = {
        rank: microbiome.rank,
        id: microbiome.ID,
        detected: microbiome.rank > 0 && microbiome.rank < 100,
      };
    });
    microbiomesData.push(payload);
  });

  return microbiomesData;
};

const getMicrobiomesPercentages = (data, length) => {
  const percentages = {};
  CATEGORIES.forEach((category) => {
    percentages[category] = { detected: 0, undetected: 0 };
  });
  data.forEach((microbiomesValues) => {
    microbiomesValues.forEach((microbiome) => {
      microbiome.rank > 0 && microbiome.rank < 100
        ? (percentages[microbiome.genus].detected += 1)
        : (percentages[microbiome.genus].undetected += 1);
    });
  });
  CATEGORIES.forEach((category) => {
    percentages[category] =
      Math.round((percentages[category].detected / length) * 100 * 100) / 100;
  });

  return percentages;
};

const getMostReccurentCountries = (array) => {
  if (!Array.isArray(array) || array.length === 0) {
    return null;
  }

  const modeMap = {};
  array.forEach((_, i) => {
    const el = array[i];
    if (el) {
      modeMap[el] = modeMap[el] == null ? 1 : modeMap[el] + 1;
    }
  });

  return modeMap;
};

const myColor = (rank) => {
  if (rank > 0 && rank < 2) return COLORS[0];
  if (rank > 1 && rank < 4) return COLORS[1];
  if (rank > 3 && rank < 10) return COLORS[2];
  if (rank >= 10 && rank < 30) return COLORS[3];
  if (rank >= 30 && rank <= 100) return COLORS[4];
};

const getColoryByDiet = (diet) => {
  switch (diet) {
    case "Omnivore":
      return DIET_COLORS[0];
    case "Vegetarian":
      return DIET_COLORS[1];
    case "Vegan":
      return DIET_COLORS[2];
    default:
      return DEFAULT_COLOR;
  }
};

const getColorByAge = (age) => {
  if (age >= 20 && age < 30) return AGE_COLORS[4];
  if (age >= 30 && age < 40) return AGE_COLORS[3];
  if (age >= 40 && age < 50) return AGE_COLORS[2];
  if (age >= 50 && age < 60) return AGE_COLORS[1];
  if (age >= 60 && age < 70) return AGE_COLORS[0];

  return DEFAULT_COLOR;
};

const getColoryByCountry = (index) => COUNTRY_COLORS[index];

const getColorByRule = {
  diet: (dietType) => getColoryByDiet(dietType),
  country: (countryName, countries = []) =>
    getColoryByCountry(countries.indexOf(countryName)),
  age: (ageValue) => getColorByAge(ageValue),
};

const getColorsByRule = {
  diet: DIET_COLORS,
  country: COUNTRY_COLORS,
  age: AGE_COLORS,
};

const getLegendTitleByRule = {
  diet: "Types of diet",
  age: "Age range",
  country: "Countries",
};

const getLegendValueByColor = (rule, index, countries = null) => {
  switch (rule) {
    case "diet":
      return getDietLegendLabelByIndex(index);
    case "age":
      return getAgeLegendLabelByIndex(index);
    case "country":
      return getCountryLegendLabelByIndex(index, countries);
    default:
      return getDefaultLegendLabelByIndex(index);
  }
};

const getDefaultLegendLabelByIndex = (color) => {
  switch (color) {
    case COLORS[0]:
      return "1";
    case COLORS[1]:
      return "2 - 3";
    case COLORS[2]:
      return "4 - 10";
    case COLORS[3]:
      return "11 - 30";
    case COLORS[4]:
      return "31 - 100";

    default:
      return "";
  }
};

const getWidthValuesByRule = (rule, i, width) => {
  switch (rule) {
    case "age":
      return width + 76;
    case "diet":
      switch (i) {
        case 0:
          return width + 65;
        case 1:
          return width + 88;
        case 2:
          return width + 84;
        default:
          return width + 88;
      }
    case "country":
      return width + 130;
    default:
      switch (i) {
        case 4:
          return width + 55;
        case 3:
          return width + 70;
        case 2:
          return width + 76;
        case 1:
          return width + 82;
        case 0:
          return width + 89;
        default:
          return width + 72;
      }
  }
};

const getComparisonValueByRule = (rule, d) => {
  switch (rule) {
    case "age":
      return d.metadata.AGE_YEARS;
    case "diet":
      return d.metadata.DIET_TYPE;
    case "country":
      return d.metadata.COUNTRY_RESIDENCE;

    default:
      return null;
  }
};

const getDietLegendLabelByIndex = (color) => {
  switch (color) {
    case DIET_COLORS[0]:
      return "Omnivore";
    case DIET_COLORS[1]:
      return "Vegetarian";
    case DIET_COLORS[2]:
      return "Vegan";

    default:
      return "";
  }
};

const getAgeLegendLabelByIndex = (color) => {
  switch (color) {
    case AGE_COLORS[4]:
      return "20-29";
    case AGE_COLORS[3]:
      return "30-39";
    case AGE_COLORS[2]:
      return "40-49";
    case AGE_COLORS[1]:
      return "50-59";
    case AGE_COLORS[0]:
      return "60-69";

    default:
      return "";
  }
};

const getCountryLegendLabelByIndex = (color, countries) =>
  countries[COUNTRY_COLORS.indexOf(color)] || "Undefined";

export {
  myColor,
  getDensityList,
  getAllMeans,
  getLegendValueByColor,
  getMostReccurentCountries,
  getWidthValuesByRule,
  getValuesByMicrobiome,
  getMicrobiomesPercentages,
  getComparisonValueByRule,
  kernelDensityEstimator,
  kernelEpanechnikov,
  median,
  getColorByRule,
  getColorsByRule,
  getLegendTitleByRule,
};
