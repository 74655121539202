import { FILTRE_DISPATCH_BARCHART } from "../../src/components/Constants/ActionsTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case FILTRE_DISPATCH_BARCHART:
      return {
        ...state,
        barSelected: action.barSelected
      };
    default:
      return state;
  }
};
