import React from "react";
import "./Chat.scss";

const displayTwoCharacters = (char) => {
  if (!char) {
    return "00";
  }
  const character = `${char}`;
  return character.length === 2 ? character : `0${character}`;
};

const displayTime = (time) => {
  const date = new Date(time);
  return `
    ${displayTwoCharacters(date.getHours())}:${displayTwoCharacters(
    date.getMinutes()
  )}`;
};

export default ({ index, name, message, date, isSelf }) => {
  return (
    <div
      className={`container-chat-message ${isSelf && "_self"}`}
      id={`message__${index}`}
    >
      <strong>{name}</strong>
      <div className={`container-message ${isSelf && "light"}`}>{message}</div>
      <span className="date-span">{displayTime(date)}</span>
    </div>
  );
};
